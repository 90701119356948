import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Divider, Form, Input, Space, Spin } from 'antd';
import { findIndex } from 'lodash';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { AppContext } from '../../../AppContext';
import { LoginLogo } from '../../../assets/svg';
import {
  MODULES_KEY,
  ROLE_KEYS,
  ROUTES,
  ROUTES_MODULES_KEY,
  WEB,
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import { signIn } from '../../../firebase';
import './auth.less';
import { GOOGLE_LOGIN, LOGIN } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const Login = ({ redirectPath }) => {
  const [form] = Form?.useForm();
  const history = useHistory();
  const { initializeAuth } = useContext(AppContext);
  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {},
  });

  function successCallback(user, accessToken, refreshToken, permissions) {
    initializeAuth(user, accessToken, refreshToken, permissions);
    if ([ROLE_KEYS.USER, ROLE_KEYS.INTERN]?.includes(user?.role)) {
      const index = findIndex(permissions, { access: { read: true } });
      const hasAlertConfigReadAccess = permissions?.find(
        (module) => module?.moduleName === MODULES_KEY?.TEAM_CALENDAR,
      )?.access?.read;
      if (
        redirectPath === ROUTES_MODULES_KEY?.TEAM_CALENDAR &&
        hasAlertConfigReadAccess
      ) {
        history?.replace(redirectPath);
      } else if (index !== -1) {
        const moduleName = permissions[index]?.moduleName;
        history?.replace(ROUTES_MODULES_KEY[moduleName]);
      } else {
        history?.replace(ROUTES?.PROJECT_RESOURCE_SHEET);
      }
    } else {
      history?.replace(ROUTES?.MAIN);
    }
  }

  const [GoogleLoginMutate, { data: googleData, loading: googleLoginLoading }] =
    useMutation(GOOGLE_LOGIN, {
      onError() {},
    });

  const googleLogin = async (res) => {
    try {
      await GoogleLoginMutate({
        variables: {
          input: {
            firebaseToken: res,
            loginFrom: WEB,
          },
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  if (googleData?.userLogin?.user) {
    const userData = googleData?.userLogin?.user;
    const accessToken = googleData?.userLogin?.accessToken;
    const tokenRefresh = googleData?.userLogin?.refreshToken;
    const permissions =
      googleData?.userLogin?.user?.permissionRole?.permissions;
    if (successCallback) {
      successCallback(userData, accessToken, tokenRefresh, permissions);
    }
  }
  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim()?.toLowerCase(),
        password: values?.password?.trim(),
      };
      const response = await loginMutate({
        variables: { input: { ...formValues } },
      });
      if (response?.data) {
        const user = response?.data?.adminLogin?.user;
        const accessToken = response?.data?.adminLogin?.accessToken;
        const refreshToken = response?.data?.adminLogin?.refreshToken;

        if (successCallback) {
          successCallback(user, accessToken, refreshToken);
        }
      } else {
        form?.setFieldsValue(values);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };
  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin
            spinning={loginLoading || googleLoginLoading}
            wrapperClassName="full-width"
          >
            <div className="text-center mb-8">
              <Space align="center">
                <LoginLogo />
              </Space>
            </div>
            <GoogleLoginButton
              onClick={async () => {
                const res = await signIn();
                if (res) {
                  googleLogin(res);
                }
              }}
            />
            <Divider>OR</Divider>
            <Form
              name="Login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
              form={form}
              layout="vertical"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required, message: 'Please Enter Email!' }, email]}
              >
                <Input
                  className="w-100"
                  prefix={<UserOutlined />}
                  placeholder="Enter Email"
                />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required, message: 'Please Enter Password!' }]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter Password"
                />
              </Form.Item>
              <Button type="primary" className="full-width" htmlType="submit">
                Login
              </Button>
            </Form>
          </Spin>
        </Card>
        <div className="text-center login-copyrights">
          Logicwind © {new Date().getFullYear()}
        </div>
      </div>
    </div>
  );
};

export default Login;
