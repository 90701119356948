import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import { MODULES_KEY, PERMISSION_OBJECT, ROUTES } from '../../common/constants';
import Resume from '../users/Resume';
import EmployeeResumes from './EmployeeResumes';
import './employeeResumes.less';

const EmployeeResumesWrapper = () => (
  <Switch>
    <Route
      exact
      path={ROUTES?.EMPLOYEE_RESUMES}
      render={(props) => (
        <AccessControl
          modulekey={MODULES_KEY?.SHARED_RESUMES}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <EmployeeResumes {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.EMPLOYEE_RESUMES}${ROUTES?.EMPLOYEE_VIEW_RESUME}`}
      render={(props) => (
        <AccessControl
          modulekey={MODULES_KEY?.EMPLOYEE_VIEW_RESUME}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <Resume {...props} />
        </AccessControl>
      )}
    />
  </Switch>
);

export default EmployeeResumesWrapper;
