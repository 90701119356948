import { FilterFilled, PhoneTwoTone, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, DatePicker, Form, Select, Space, Tag } from 'antd';
import { Option } from 'antd/es/mentions';
import dayjs from 'dayjs';
import { capitalize, debounce, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PhoneSlash } from '../../assets/svg';
import {
  BACKEND_DATE_FORMAT,
  DATE_FILTERS,
  DATE_TIME_FORMAT,
  DEFAULT_DATE_FORMAT,
  HOUR_LOGS_DATE_TIME_FORMAT,
  INCIDENT_STATUS,
  LIMIT,
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROLE_KEYS,
  ROUTES_MODULES_KEY,
  SEVERITY_STATUS,
  USER,
} from '../../common/constants';
import CommonSelect from '../../components/CommonSelect';
import CommonTable from '../../components/CommonTable';
import VerifyPermissions from '../../components/VerifyPermissions';
import { INCIDENT_MASTERS } from '../incidentMaster/graphql/Queries';
import IncidentModal from './components/IncidentModal';
import { UPDATE_INCIDENT_REPORT } from './graphql/Mutations';
import { DESIGNATIONS, INCIDENTS_LISTING } from './graphql/Queries';

const { RangePicker } = DatePicker;

const initialPagination = {
  current: 1,
  pageSize: LIMIT,
  total: 0,
};

const initialFilter = {
  skip: 0,
  sortOrder: 'DESC',
  sortField: 'updatedAt',
  departmentId: '',
  search: '',
  status: null,
  severity: null,
  incidentMasterId: null,
};

function IncidentReport() {
  // eslint-disable-next-line no-undef
  const user = JSON.parse(localStorage.getItem(USER));
  const history = useHistory();
  const [userSearch, setUserSearch] = useState();
  const [form] = Form?.useForm();
  const [paginationProp, setPaginationProp] = useState(initialPagination);
  const [filterProp, setFilterProp] = useState(initialFilter);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [filterVisible, setFilterVisible] = useState({
    statusFilterVisible: false,
    severityFilterVisible: false,
    categoryFilterVisible: false,
  });
  const [selectedStatus, setSelectedStatus] = useState();
  const [masterId, setMasterId] = useState();
  const [selectedSeverity, setSelectedSeverity] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [incidentDateFilterActive, setIncidentDateFilterActive] =
    useState(false);
  const [createdAtFilterActive, setCreatedAtFilterActive] = useState(false);
  const [updatedAtFilterActive, setUpdatedAtFilterActive] = useState(false);
  const isCreatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.INCIDENT_REPORT,
    allowedPermissions: [PERMISSION_OBJECT?.create],
  });
  const disabledDate = (current) =>
    current && current?.isAfter(dayjs().endOf('day'));

  const [executeQuery, { loading, data }] = useLazyQuery(INCIDENTS_LISTING, {
    variables: { filters: filterProp },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        total: res?.incidentReportsAdmin?.count,
      };
      setPaginationProp(pagination);
    },
    onError: () => {},
  });

  const [executeUpdateQuery] = useMutation(UPDATE_INCIDENT_REPORT, {
    onCompleted: () => {
      setModalVisible(false);
      executeQuery({
        variables: {
          filters: {
            ...filterProp,
            limit: paginationProp?.pageSize,
          },
        },
      });
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error('Error :', error);
    },
  });

  useEffect(() => {
    executeQuery({
      variables: {
        filters: {
          ...filterProp,
          limit: paginationProp?.pageSize,
        },
      },
    });
  }, []);

  const handleSearch = debounce((value) => {
    const newInitialFilter = {
      ...filterProp,
      skip: 0,
      search: value?.length > 0 ? value?.toString()?.trim()?.split(',') : [],
    };
    setPaginationProp({ ...paginationProp, current: 1 });
    setFilterProp(newInitialFilter);
    executeQuery({
      variables: {
        filters: {
          ...newInitialFilter,
          limit: paginationProp?.pageSize,
        },
      },
    });
  }, 500);

  const onChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const filter = { skip: (current - 1) * pageSize };
    const sortOrder = sorter?.order === 'ascend' ? 'ASC' : 'DESC';
    const sortField = sorter?.field;
    const newFilterProp = { ...filterProp, ...filter, sortOrder, sortField };

    setFilterProp(newFilterProp);
    setPaginationProp({ ...paginationProp, current, pageSize });
    executeQuery({
      variables: {
        filters: {
          ...newFilterProp,
          skip: filter?.skip,
          limit: pageSize,
          severity: selectedSeverity,
          status: selectedStatus,
          sortField,
          sortOrder,
          incidentMasterId: masterId,
        },
      },
    });
  };

  const handleEditSubmit = async (formData) => {
    try {
      await executeUpdateQuery({
        variables: {
          data: {
            ...formData,
            reasonForRejection: ' ',
            reasonForResolving: ' ',
            mom: formData?.mom,
            scheduledOn: dayjs(formData?.scheduledOn)?.format(
              HOUR_LOGS_DATE_TIME_FORMAT,
            ),
          },
          where: selectedIncident?.id,
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error :', error);
    }
  };
  const handleFilter = () => {
    const newFilter = {
      ...filterProp,
      skip: 0,
      status: selectedStatus,
      limit: paginationProp?.pageSize,
      incidentMasterId: masterId,
    };
    setFilterProp(newFilter);
    setPaginationProp({ ...paginationProp, current: 1 });
    executeQuery({
      variables: {
        filters: newFilter,
      },
    });
    setFilterVisible({
      ...filterVisible,
      statusFilterVisible: false,
      categoryFilterVisible: false,
    });
  };

  const handleSeverityFilter = (value) => {
    setSelectedSeverity(value);
    const newFilter = {
      ...filterProp,
      skip: 0,
      severity: value,
      limit: paginationProp?.pageSize,
    };
    setFilterProp(newFilter);
    setPaginationProp({ ...paginationProp, current: 1 });
    executeQuery({
      variables: {
        filters: newFilter,
      },
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setMasterId(null);
    setSelectedStatus(null);
    const newFilter = {
      ...filterProp,
      skip: 0,
      status: null,
      limit: paginationProp?.pageSize,
      incidentMasterId: null,
    };
    setFilterProp(newFilter);
    setPaginationProp({ ...paginationProp, current: 1 });
    executeQuery({
      variables: {
        filters: newFilter,
      },
    });
  };

  const handleIncidentDateFilter = (dates) => {
    const [startDate, endDate] = dates || [];
    const newFilter = {
      ...filterProp,
      incidentDateFilter: {
        startDate: startDate ? startDate?.format(BACKEND_DATE_FORMAT) : null,
        endDate: endDate ? endDate?.format(BACKEND_DATE_FORMAT) : null,
      },
      skip: 0,
      limit: paginationProp?.pageSize,
    };
    setPaginationProp({ ...paginationProp, current: 1 });
    setFilterProp(newFilter);
    setIncidentDateFilterActive(!!startDate || !!endDate);
    executeQuery({
      variables: {
        filters: newFilter,
      },
    });
  };

  const handleCreatedAtFilter = (dates) => {
    const [startDate, endDate] = dates || [];
    const newFilter = {
      ...filterProp,
      createdAtFilter: {
        startDate: startDate ? startDate?.format(BACKEND_DATE_FORMAT) : null,
        endDate: endDate ? endDate?.format(BACKEND_DATE_FORMAT) : null,
      },
      skip: 0,
      limit: paginationProp?.pageSize,
    };
    setPaginationProp({ ...paginationProp, current: 1 });
    setFilterProp(newFilter);
    setCreatedAtFilterActive(!!startDate || !!endDate);
    executeQuery({
      variables: {
        filters: newFilter,
      },
    });
  };

  const handleUpdatedAtFilter = (dates) => {
    const [startDate, endDate] = dates || [];
    const newFilter = {
      ...filterProp,
      updatedAtFilter: {
        startDate: startDate ? startDate?.format(BACKEND_DATE_FORMAT) : null,
        endDate: endDate ? endDate?.format(BACKEND_DATE_FORMAT) : null,
      },
      skip: 0,
      limit: paginationProp?.pageSize,
    };
    setPaginationProp({ ...paginationProp, current: 1 });
    setFilterProp(newFilter);
    setUpdatedAtFilterActive(!!startDate || !!endDate);
    executeQuery({
      variables: {
        filters: newFilter,
      },
    });
  };

  const handleDateFilter = (filterName, dates) => {
    switch (filterName) {
      case DATE_FILTERS.INCIDENT_DATE:
        handleIncidentDateFilter(dates);
        break;
      case DATE_FILTERS.CREATED_AT:
        handleCreatedAtFilter(dates);
        break;
      case DATE_FILTERS.UPDATED_AT:
        handleUpdatedAtFilter(dates);
        break;
      default:
        break;
    }
  };

  const handleUserSearch = debounce((value) => {
    setUserSearch(value);
  }, 500);

  const getCategoryFilterPops = () => ({
    filterDropdownOpen: filterVisible?.categoryFilterVisible,
    onFilterDropdownOpenChange: (visible) =>
      setFilterVisible({ ...filterVisible, categoryFilterVisible: visible }),
    filterDropdown: () => (
      <div className="columnFilter" onKeyDown={(e) => e?.stopPropagation()}>
        <Space direction="vertical">
          <Form name="control-ref" form={form} layout="vertical">
            <Form.Item name="category">
              <CommonSelect
                placeholder="Select Category"
                showSearch
                query={INCIDENT_MASTERS}
                onSearch={handleUserSearch}
                responsePath="incidentMasters.data"
                valuePath="id"
                labelPath="name"
                fetchPolicy="network-only"
                variables={{
                  filters: { search: userSearch, statusFilter: 'ACTIVE' },
                }}
                onChange={(e) => {
                  if (e) {
                    setMasterId(e);
                  } else {
                    setMasterId();
                  }
                }}
                useEffectDeps={[userSearch]}
                virtual={false}
              />
            </Form.Item>
          </Form>
          <Space direction="horizontal">
            <Button size="small" onClick={handleFilter}>
              Apply
            </Button>
            <Button
              danger
              size="small"
              onClick={() => {
                handleReset();
                setFilterVisible({
                  ...filterVisible,
                  categoryFilterVisible: false,
                });
              }}
            >
              Reset
            </Button>
          </Space>
        </Space>
      </div>
    ),
    filterIcon: () => (
      <FilterFilled
        className={filterProp?.incidentMasterId ? 'filter-filled-data' : ''}
      />
    ),
  });

  const getStatusFilterProps = () => ({
    filterDropdownOpen: filterVisible?.statusFilterVisible,
    onFilterDropdownOpenChange: (visible) =>
      setFilterVisible({ ...filterVisible, statusFilterVisible: visible }),
    filterDropdown: () => (
      <div className="columnFilter" onKeyDown={(e) => e?.stopPropagation()}>
        <Space direction="vertical">
          <Form name="control-ref" form={form} layout="vertical">
            <Form.Item name="status">
              <Select
                allowClear
                className="w-150 mr-8 mb-5"
                placeholder="Sort By Status"
                onChange={(e) => {
                  if (e) {
                    setSelectedStatus(e);
                  } else {
                    setSelectedStatus();
                  }
                }}
              >
                {map(INCIDENT_STATUS, (status) => (
                  <Option key={status} value={status} label={status}>
                    {capitalize(status)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          <Space direction="horizontal">
            <Button size="small" onClick={handleFilter}>
              Apply
            </Button>
            <Button
              danger
              size="small"
              onClick={() => {
                handleReset();
                setFilterVisible({
                  ...filterVisible,
                  statusFilterVisible: false,
                });
              }}
            >
              Reset
            </Button>
          </Space>
        </Space>
      </div>
    ),
    filterIcon: () => (
      <FilterFilled
        className={filterProp?.status ? 'filter-filled-data' : ''}
      />
    ),
  });

  const getDateFilterProps = (filterName, activeState) => ({
    filterDropdown: ({ confirm }) => (
      <div className="columnFilter" onKeyDown={(e) => e?.stopPropagation()}>
        <Space direction="vertical">
          <RangePicker
            onChange={(dates) => {
              handleDateFilter(filterName, dates);
              confirm();
            }}
            onClear={() => {
              handleDateFilter(filterName, []);
              confirm();
            }}
            disabledDate={disabledDate}
          />
        </Space>
      </div>
    ),
    filterIcon: () => (
      <FilterFilled className={activeState ? 'filter-filled-data' : ''} />
    ),
  });

  const getColorForSeverity = (severity) => {
    switch (severity) {
      case SEVERITY_STATUS.MEDIUM:
        return 'severity-medium';
      case SEVERITY_STATUS.LOW:
        return 'severity-low';
      default:
        return 'severity-high';
    }
  };

  const handleRowClick = (record) => {
    const handleRow = () => {
      setSelectedIncident(record);
      setModalVisible(true);
      setIsEdit(true);
    };

    return {
      onClick: handleRow,
    };
  };

  const columns = [
    {
      title: 'Reported By',
      dataIndex: 'raisedByUser',
      key: 'raisedByUser',
      render: (value, record) => (
        <div className="incident-severity-indicator">
          <span
            className={`severity-color ${getColorForSeverity(
              record?.severity,
            )}`}
          />
          <div className="incident-details">
            {value?.displayName || value?.firstName}
            <p className="name-column">
              [
              {`${record?.raisedByUser?.department?.name}>${record?.raisedByUser?.jobTitle}`}
              ]
            </p>
          </div>
        </div>
      ),
    },
    {
      title: 'Reporting On',
      dataIndex: 'reportingForUser',
      key: 'reportingForUser',
      render: (value, record) => (
        <>
          <div className="d-flex discussion-required">
            <div className="is-discussion-required" style={{ width: '30px' }}>
              {record?.isDiscussionRequired ? (
                <PhoneTwoTone size="small" />
              ) : (
                <PhoneSlash />
              )}
            </div>
            <div>
              {value?.displayName || value?.firstName}
              <p className="name-column">
                [
                {`${record?.reportingForUser?.department?.name}>${record?.reportingForUser?.jobTitle}`}
                ]
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'incidentMaster',
      key: 'incidentMaster',
      ...getCategoryFilterPops(),
      render: (record) => (record ? record?.name : 'N/A'),
    },
    {
      title: 'Task/Project',
      dataIndex: 'projectName',
      key: 'projectName',
      render: (text) => capitalize(text),
    },
    {
      title: 'Incident Date',
      dataIndex: 'date',
      key: 'date',
      render: (value) => dayjs(value)?.format(DEFAULT_DATE_FORMAT),
      ...getDateFilterProps(
        DATE_FILTERS.INCIDENT_DATE,
        incidentDateFilterActive,
      ),
    },
    {
      title: 'Reported Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (value) => dayjs(value)?.format(DATE_TIME_FORMAT),
      ...getDateFilterProps(DATE_FILTERS.CREATED_AT, createdAtFilterActive),
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      render: (value) => dayjs(value)?.format(DATE_TIME_FORMAT),
      ...getDateFilterProps(DATE_FILTERS.UPDATED_AT, updatedAtFilterActive),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      ...getStatusFilterProps(),
      render: (status) => {
        const formattedStatus = status || INCIDENT_STATUS.PENDING;
        let color;
        if (formattedStatus === INCIDENT_STATUS.RESOLVED) {
          color = 'green';
        } else if (formattedStatus === INCIDENT_STATUS.INITIATED) {
          color = 'blue';
        } else if (formattedStatus === INCIDENT_STATUS.REJECTED) {
          color = 'red';
        } else {
          color = 'yellow';
        }
        return <Tag color={color}>{formattedStatus}</Tag>;
      },
    },
  ];
  const renderMaxTag = (omittedValues) => {
    if (omittedValues?.length === 0) {
      return null;
    }
    return <span>+ {omittedValues?.length} more...</span>;
  };
  return (
    <div className="table-card-page">
      <Card
        className="ant-body-scroll"
        title="Incident Reports"
        extra={
          <>
            <span className="incident-report-select">
              <Space>
                {![ROLE_KEYS?.SUPER_ADMIN, ROLE_KEYS?.ADMIN]?.includes(
                  user?.role,
                ) &&
                  isCreatePermission && (
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() =>
                        history?.push({
                          pathname: `${ROUTES_MODULES_KEY?.INCIDENT_REPORT}/create`,
                        })
                      }
                    >
                      Create Incident
                    </Button>
                  )}
                <Select
                  className="severity-select"
                  placeholder="Select The Severity"
                  allowClear
                  onChange={handleSeverityFilter}
                  value={selectedSeverity}
                >
                  {map(SEVERITY_STATUS, (status) => (
                    <Option key={status} value={status} label={status}>
                      {capitalize(status)}
                    </Option>
                  ))}
                </Select>
                <CommonSelect
                  className="incident-select"
                  mode="tags"
                  placeholder="Search Name, Department, Designation"
                  showSearch
                  query={DESIGNATIONS}
                  onChange={handleSearch}
                  responsePath="designations.designations"
                  valuePath="jobTitle"
                  labelPath="jobTitle"
                  fetchPolicy="network-only"
                  onClear={() =>
                    setFilterProp({
                      ...filterProp,
                      searchStrings: [],
                    })
                  }
                  value={filterProp?.searchStrings}
                  maxTagCount={3}
                  maxTagPlaceholder={renderMaxTag}
                  virtual={false}
                />
              </Space>
            </span>
          </>
        }
      >
        <CommonTable
          onRow={handleRowClick}
          size="small"
          bordered
          rowClassName="pointer"
          columns={columns}
          dataSource={data?.incidentReportsAdmin?.data}
          loadingData={loading}
          onChange={onChange}
          paginationConfig={paginationProp}
          rowKey="id"
        />
      </Card>
      {modalVisible && (
        <IncidentModal
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          onSubmit={handleEditSubmit}
          incident={selectedIncident}
          mode={isEdit}
        />
      )}
    </div>
  );
}

export default IncidentReport;
