import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const RESUME_LINK = gql`
  mutation SharedResumesWithConfigurations(
    $input: ShareResumesWithConfigurationsInput!
  ) {
    sharedResumesWithConfigurations(input: $input) {
      message
    }
  }
`;

export const GENERATE_RESUME_DOC_LINK = gql`
  mutation ($input: GenerateResumeDocsInput!) {
    generateResumeDocs(input: $input) {
      fileIds
      message
    }
  }
`;
