import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Error404 from '../Error404';
import { ROUTES, ROUTES_MODULES_KEY } from '../common/constants';
import history from '../historyData';
import AlertConfigWrapper from '../modules/alertConfig';
import assetsWrapper from '../modules/assetsManagement';
import ConfigurationWrapper from '../modules/configuration';
import EmailDesignWrapper from '../modules/email-signature';
import EmployeeResumesWrapper from '../modules/employeeResumes';
import EventWrapper from '../modules/event';
import HolidaysWrapper from '../modules/holidays';
import HourLogSummaryWrapper from '../modules/hourLogSummary';
import HourLogsHistoryWrapper from '../modules/hourLogsHistory';
import IncidentMaster from '../modules/incidentMaster/IncidentMaster';
import IncidentMasterWrapper from '../modules/incidentMaster/index';
import IncidentReportWrapper from '../modules/incidentReport/Index';
import LeaveWrapper from '../modules/leave';
import LogsWrapper from '../modules/logs';
import MetaReportsWrapper from '../modules/metaReports';
import ProjectResourceWrapper from '../modules/projectResourceSheet';
import ProjectWrapper from '../modules/projects';
import ResourceViewWrapper from '../modules/quarterView';
import ResourceAllocationWrapper from '../modules/resourceAllocation';
import ResourceAllocationTestingWrapper from '../modules/resourceAllocationTesting';
import ResourceAllocationWrapperV2 from '../modules/resourceAllocationV2';
import RoleWrapper from '../modules/role';
import RsvpWrapper from '../modules/rsvp';
import StatisticsWrapper from '../modules/statistics';
import UsersWrapper from '../modules/users';

const ContentRoutes = () => {
  const routes = [
    {
      path: ROUTES?.RESOURCE_ALLOCATION_OLD,
      component: ResourceAllocationWrapper,
    },
    {
      path: ROUTES?.RESOURCE_ALLOCATION,
      component: ResourceAllocationWrapperV2,
    },
    {
      path: ROUTES?.RESOURCE_ALLOCATION_TESTING,
      component: ResourceAllocationTestingWrapper,
    },
    {
      path: ROUTES?.QUARTER_VIEW,
      component: ResourceViewWrapper,
    },
    { path: ROUTES?.LEAVES, component: LeaveWrapper },
    {
      path: ROUTES_MODULES_KEY?.INCIDENT_REPORT,
      component: IncidentReportWrapper,
    },
    {
      path: ROUTES?.ASSETS_TRACKER,
      render: () => <Redirect to={`${ROUTES_MODULES_KEY?.ASSETS_LISTING}`} />,
    },
    {
      path: ROUTES?.INCIDENT_MASTER,
      component: IncidentMasterWrapper,
    },
    {
      path: `${ROUTES_MODULES_KEY?.ASSETS_LISTING}`,
      component: assetsWrapper,
    },
    {
      path: `${ROUTES_MODULES_KEY?.ASSETS_BOOKING}`,
      component: assetsWrapper,
    },
    {
      path: ROUTES?.EVENTS,
      render: () => <Redirect to={`${ROUTES_MODULES_KEY?.EVENT_MANAGEMENT}`} />,
    },
    {
      path: `${ROUTES_MODULES_KEY?.EVENT_MANAGEMENT}`,
      component: EventWrapper,
    },
    {
      path: `${ROUTES_MODULES_KEY?.RSVP_MANAGEMENT}`,
      component: RsvpWrapper,
    },
    {
      path: ROUTES?.SETTINGS,
      render: () => <Redirect to={`${ROUTES_MODULES_KEY?.USERS}`} />,
    },
    {
      path: `${ROUTES_MODULES_KEY?.USERS}`,
      component: UsersWrapper,
    },
    {
      path: `${ROUTES_MODULES_KEY?.PROJECTS}`,
      component: ProjectWrapper,
    },
    {
      path: `${ROUTES_MODULES_KEY?.ROLE}`,
      component: RoleWrapper,
    },
    {
      path: `${ROUTES_MODULES_KEY?.HOLIDAYS}`,
      component: HolidaysWrapper,
    },
    {
      path: `${ROUTES_MODULES_KEY?.CONFIGURATIONS}`,
      component: ConfigurationWrapper,
    },
    {
      path: `${ROUTES_MODULES_KEY?.TEAM_CALENDAR}`,
      component: AlertConfigWrapper,
    },
    {
      path: ROUTES?.LOGS,
      render: () => <Redirect to={`${ROUTES_MODULES_KEY?.LOGS}`} />,
    },
    {
      path: ROUTES_MODULES_KEY?.INCIDENT_MASTER,
      component: IncidentMaster,
    },
    {
      path: `${ROUTES_MODULES_KEY?.LOGS}`,
      component: LogsWrapper,
    },
    {
      path: `${ROUTES_MODULES_KEY?.HOURS_LOG_HISTORY}`,
      component: HourLogsHistoryWrapper,
    },
    {
      path: `${ROUTES_MODULES_KEY?.HOUR_LOG_SUMMARY}`,
      component: HourLogSummaryWrapper,
    },
    {
      path: ROUTES?.PROFILE,
      render: () => <Redirect to={`${ROUTES_MODULES_KEY?.EMAIL_SIGNATURE}`} />,
    },
    {
      path: `${ROUTES_MODULES_KEY?.EMAIL_SIGNATURE}`,
      component: EmailDesignWrapper,
    },

    // comment for future use
    // { path: ROUTES?.UPLOAD_WFH_SHEET, component: UploadWfhSheetWrapper },
    { path: ROUTES?.STATISTICS, component: StatisticsWrapper },
    { path: ROUTES?.META_REPORTS, component: MetaReportsWrapper },
    { path: ROUTES?.EMPLOYEE_RESUMES, component: EmployeeResumesWrapper },
    // comment for future use
    // {
    //   path: ROUTES?.FOOD_COUPONS,
    //   component: FoodCouponWrapper,
    // },
    { path: ROUTES?.PROJECT_RESOURCE_SHEET, component: ProjectResourceWrapper },
    {
      path: ROUTES?.MAIN,
      render: () => <Redirect to={`${ROUTES?.SETTINGS}${ROUTES?.USERS}`} />,
    },
  ];

  return (
    <Switch>
      {routes?.map((route, index) => {
        if (history?.location?.pathname === ROUTES?.LOGOUT) {
          // eslint-disable-next-line no-undef
          localStorage?.clear();
          history?.replace(ROUTES?.MAIN);
        }
        if (route?.render) {
          return (
            <Route
              key={`route-${index + 1}`}
              path={route?.path}
              exact
              render={route?.render}
            />
          );
        }
        return (
          <Route
            key={`route-${index + 1}`}
            path={route?.path}
            component={route?.component}
          />
        );
      })}
      <Route path="*" component={Error404} />
    </Switch>
  );
};

export default ContentRoutes;
