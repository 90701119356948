import { Avatar, Divider, List, Typography } from 'antd';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import React from 'react';
import { BACKEND_MONTH_FORMAT } from '../../../../common/constants';
import MobileLogoComponent from '../../../../components/MobileLogoComponent';

const { Title } = Typography;

const UserResume = ({ data }) => {
  const {
    basicDetails,
    totalExperience,
    educationDetails,
    professionalSummary,
    projectDetails,
  } = data?.resumeData || data?.employeeResume || {};
  return (
    <div className="resume-container">
      <div className="resume-header">
        <div className="resume-header-detail">
          {basicDetails?.profileImage && (
            <div>
              <Avatar size={100} src={basicDetails?.profileImage} />
            </div>
          )}
          <div className="resume-description">
            <Title level={1}>
              {basicDetails?.displayName
                ? `${basicDetails?.displayName}`
                : `${basicDetails?.shortName}`}
            </Title>
            <Title level={4}>{basicDetails?.jobTitle}</Title>
            <Title level={4}>
              {totalExperience === basicDetails?.logicwindExperience
                ? `${totalExperience} of Total Experience, All with LogicWind.`
                : `${totalExperience} of total experience which includes ${basicDetails?.logicwindExperience} dedicated to logicwind.`}
            </Title>
          </div>
        </div>
        <div className="resume-logo">
          <MobileLogoComponent className="mr-0" />
        </div>
      </div>
      <Divider className="mb-0" />
      <div className="resume-body">
        <div className="resume-sidebar">
          {educationDetails?.length > 0 && (
            <>
              <Title level={2}>Education</Title>
              {educationDetails?.map((education) => (
                <div className="education-container" key={education?.id}>
                  <Title level={3}>{education?.university}</Title>
                  <p>{`${education?.degree}, ${education?.branch}`}</p>
                  <p>
                    Year Of Joining:{' '}
                    {dayjs(education?.yearOfJoining)?.format(
                      BACKEND_MONTH_FORMAT,
                    )}
                  </p>
                  <p>
                    Year Of Completion:{' '}
                    {dayjs(education?.yearOfCompletion)?.format(
                      BACKEND_MONTH_FORMAT,
                    )}
                  </p>
                </div>
              ))}
            </>
          )}
          {basicDetails?.skills?.map(
            (skills) =>
              skills?.skillValue?.length > 0 && (
                <List
                  className="resume-skill-listing"
                  key={skills?.id}
                  header={
                    <Title level={2}>{capitalize(skills?.skillName)}</Title>
                  }
                  bordered={false}
                  dataSource={skills?.skillValue}
                  renderItem={(value) => (
                    <List.Item key={skills?.id}>
                      <p>{value}</p>
                    </List.Item>
                  )}
                />
              ),
          )}
        </div>
        <div className="resume-flex-container">
          {professionalSummary && (
            <div>
              <Title level={2}>Professional Summary </Title>
              <p className="text-justify">{professionalSummary}</p>
            </div>
          )}
          {projectDetails?.length > 0 && (
            <div>
              <Title level={2} className="project-title">
                Logicwind Projects
              </Title>
              {projectDetails?.map((project) => (
                <div key={project?.id}>
                  <Title level={3}>{project?.name}</Title>
                  <p className="text-justify">{project?.description}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserResume;
