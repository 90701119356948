import { DeleteOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Divider, Form, Input, Modal, Select, Spin } from 'antd';
import { debounce, keys, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from '../../../apollo';
import {
  ATTENDANCE,
  LIMIT,
  REGEX,
  ROUTES_MODULES_KEY,
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonInput from '../../../components/CommonInput';
import CommonSelect from '../../../components/CommonSelect';
import { GET_EVENTS, GET_SINGLE_EVENT } from '../../event/graphql/Queries';
import { CREATE_ATTENDEE, UPDATE_ATTENDEE } from '../graphql/Mutation';
import {
  GET_ATTENDEE_TYPES,
  GET_SINGLE_ATTENDEE,
  USERS,
} from '../graphql/Queries';

const RsvpModal = (props) => {
  const { showModal, setShowModal, refetch } = props;
  const history = useHistory();
  const { rsvpId } = useParams();
  const { Option } = Select;
  const [form] = Form.useForm();
  const { required, email } = formValidatorRules;
  const [eventSearch, setEventSearch] = useState();
  const [isSponsored, setIsSponsored] = useState();
  // comment for future use
  // const [eventList, setEventList] = useState([]);
  // const [selectedAttendeeType, setSelectedAttendeeType] = useState();
  // const [totalGuest, setTotalGuest] = useState();
  // const [selectedPaymentStatus, setSelectedPaymentStatus] = useState();
  const [maxGuests, setMaxGuests] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [editData, setEditData] = useState();
  const [searchStrings, setSearchStrings] = useState([]);

  const guests = [];
  // comment for future use
  // const { loading: eventLoading } = useQuery(GET_EVENTS, {
  //   variables: {
  //     filter: {
  //       skip: 0,
  //     },
  //   },
  //   fetchPolicy: 'network-only',
  //   onCompleted(res) {
  //     setEventList(res?.eventsAdmin?.events);
  //   },
  // });

  const [fetchSelectedEvent] = useLazyQuery(GET_SINGLE_EVENT, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setSelectedEvent(res?.event?.data);
      setMaxGuests(res?.event?.data?.noOfGuests);
    },
    onError() {},
  });

  const [fetchSingleAttendee, { loading: editLoading, data: updateData }] =
    useLazyQuery(GET_SINGLE_ATTENDEE, {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        setEditData(res?.attendeeDetails);
        form.setFieldsValue({
          ...res?.attendeeDetails,
          userId:
            res?.attendeeDetails?.user?.displayName ||
            res?.attendeeDetails?.user?.firstName,
        });
      },
      onError() {},
    });

  useEffect(() => {
    if (rsvpId) {
      fetchSingleAttendee({
        variables: {
          attendeeDetailsId: rsvpId,
        },
      });
    }
  }, [rsvpId]);

  useEffect(() => {
    if (editData) {
      fetchSelectedEvent({
        variables: {
          eventId: editData?.eventId,
        },
      });
    }
  }, [editData]);

  const [createAttendee, { loading: createLoading }] =
    useMutation(CREATE_ATTENDEE);
  const [updateAttendee, { loading: updateLoading }] =
    useMutation(UPDATE_ATTENDEE);

  useEffect(() => {
    if (selectedEvent) {
      guests.push({});

      // comment for future use
      // form.setFieldsValue({
      //   guests,
      // });
    }
  }, [selectedEvent]);

  const { data: attendeeType, loading: attendeeTypeLoading } = useQuery(
    GET_ATTENDEE_TYPES,
    {
      fetchPolicy: 'network-only',
    },
  );

  const handleCancel = () => {
    setShowModal(false);
    history.push(`${ROUTES_MODULES_KEY?.RSVP_MANAGEMENT}`);
  };

  useEffect(() => {
    if (updateData?.attendeeDetails?.attendeeType?.key === 'SPONSOR') {
      setIsSponsored(true);
    }
  }, [updateData]);

  // comment for future use
  // useEffect(() => {
  //   if (updateData)
  //     fetchSelectedEvent({
  //       variables: {
  //         eventId: updateData?.attendeeDetails?.eventId,
  //       },
  //     });
  // }, [selectedEvent]);

  // comment for future use
  // useEffect(() => {
  //   // eslint-disable-next-line no-console
  //   if (isSponsored) {
  //     if (typeof data?.event?.data !== 'object') {
  //       setMaxGuests(data?.event?.data);
  //     } else {
  //       const noOfTable = get(editData, 'noOfTables');
  //       const seatsPerTable = get(editData, 'seatsPerTable');
  //       const pricePerTable = get(editData, 'pricePerTable');
  //       const noOfGuests = noOfTable * seatsPerTable;

  //       setTotalGuest((noOfTable || 0) * (pricePerTable || 0));
  //       setMaxGuests(noOfGuests - 1);
  //     }
  //     form.setFieldsValue({
  //       guests: updateData?.attendeeDetails?.guests,
  //     });
  //   } else {
  //     if (updateData)
  //       fetchSelectedEvent({
  //         variables: {
  //           eventId: updateData?.attendeeDetails?.eventId,
  //         },
  //       });
  //     const noOfTable = get(updateData?.attendeeDetails, 'noOfTables');
  //     const pricePerTable = get(updateData?.attendeeDetails, 'pricePerTable');

  //     setTotalGuest((noOfTable || 0) * (pricePerTable || 0));

  //     setMaxGuests(parseInt(get(data?.event?.data, 'noOfGuests')) || 0);
  //     form.setFieldsValue({
  //       guests: updateData?.attendeeDetails?.guests,
  //     });
  //   }
  // }, [selectedEvent, isSponsored, updateData]);

  // comment for future use
  // const handleEventChange = (value) => {
  //   if (value)
  //     fetchSelectedEvent({
  //       variables: {
  //         eventId: value,
  //       },
  //     });
  // };

  const handleEvent = (value) => {
    if (value)
      fetchSelectedEvent({
        variables: {
          eventId: value,
        },
      });
    setSelectedEvent(value);
    setEventSearch('');
  };

  const handleEventSearch = debounce((value) => {
    setEventSearch(value);
  }, 500);

  // comment for future use
  // const handleAttendeeChange = (value) => {
  //   const types = find(
  //     attendeeType?.attendeeTypes?.data,
  //     (type) => type.id === value,
  //   );
  //   setSelectedAttendeeType(types);
  //   if (types) {
  //     setIsSponsored(types?.key === 'SPONSOR');
  //   } else {
  //     setIsSponsored();
  //   }
  // };

  // comment for future use
  // const onPaymentStatusChange = (e) => {
  //   setSelectedPaymentStatus(e);
  // };

  const onFinish = async (formValues) => {
    try {
      const variables = { ...formValues };
      let parsedData;
      if (editData) {
        if (formValues?.guests) {
          parsedData = JSON.parse(
            JSON.stringify(formValues?.guests),
            (key, value) => {
              if (key !== '__typename' && key !== 'id') {
                return value;
              }
            },
          );
        }
        const response = await updateAttendee({
          variables: {
            input: {
              ...variables,
              guests: parsedData,
              attendeeId: rsvpId,
              typeId: attendeeType?.attendeeTypes?.data?.[1]?.id,
              userId: undefined,
              // comment for future use
              // noOfTables: Number(formValues?.noOfTables),
              // pricePerTable: Number(formValues?.pricePerTable),
              // seatsPerTable: Number(formValues?.seatsPerTable),
            },
          },
        });
        if (response?.data?.updateAttendee) {
          form.resetFields();
          history.push(`${ROUTES_MODULES_KEY?.RSVP_MANAGEMENT}`);
          setShowModal(false);
          refetch();
        }
      } else {
        const response = await createAttendee({
          variables: {
            input: {
              ...variables,
              typeId: attendeeType?.attendeeTypes?.data?.[1]?.id,
              // comment for future use
              // noOfTables: Number(formValues?.noOfTables),
              // seatsPerTable: Number(formValues?.seatsPerTable),
              // pricePerTable: Number(formValues?.pricePerTable),
            },
          },
        });
        if (response?.data?.createAttendee) {
          form.resetFields();
          history.push(`${ROUTES_MODULES_KEY?.RSVP_MANAGEMENT}`);
          setShowModal(false);
          refetch();
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleOK = () => {
    form.submit();
  };

  useEffect(() => {
    form.setFieldsValue({
      typeId: attendeeType?.attendeeTypes?.data?.[1]?.value,
    });
  }, [attendeeType]);

  const handleSearch = debounce((value) => {
    setSearchStrings(value?.toLowerCase());
  }, 500);

  const handleSelectUser = () => setSearchStrings([]);

  return (
    <Modal
      centered
      onCancel={handleCancel}
      onOk={handleOK}
      open={showModal}
      title={editData ? 'Update Attendee' : 'Add Attendee'}
      width="1200px"
      okText={editData ? 'Save' : 'Add'}
      className="rsvp-modal"
      confirmLoading={createLoading || updateLoading}
    >
      <div>
        {editLoading ? (
          <div className="text-align-center">
            <Spin />
          </div>
        ) : (
          <Form
            form={form}
            onFinish={onFinish}
            // comment for future use
            // onValuesChange={() => {
            //   const noOfTable = form && form.getFieldValue('noOfTables');
            //   const seatsPerTable = form && form.getFieldValue('seatsPerTable');
            //   const pricePerTable = form && form.getFieldValue('pricePerTable');
            //   setTotalGuest((noOfTable || 0) * (pricePerTable || 0));
            //   if (isSponsored) {
            //     let noOfGuests = noOfTable * seatsPerTable;
            //     const spouseName = form && form.getFieldValue('spouseName');
            //     if (spouseName) {
            //       noOfGuests -= 1;
            //     }
            //     setMaxGuests(noOfGuests - 1);
            //   }
            // }}
          >
            <h4>Select Event</h4>
            <div className="d-flex add-attendee-event">
              <Form.Item name="eventId" rules={[required]}>
                <CommonSelect
                  placeholder="Select Event"
                  showSearch
                  query={GET_EVENTS}
                  onChange={handleEvent}
                  onSearch={handleEventSearch}
                  variables={{
                    filters: { skip: 0, limit: LIMIT, search: eventSearch },
                  }}
                  useEffectDeps={[eventSearch]}
                  responsePath="eventsAdmin.events"
                  valuePath="id"
                  labelPath="name"
                  fetchPolicy="network-only"
                  virtual={false}
                  disabled={editData}
                />
              </Form.Item>
              <Form.Item name="typeId" rules={[required]}>
                <Select
                  placeholder="Attendee Type"
                  allowClear
                  loading={attendeeTypeLoading}
                  // comment for future use
                  // onChange={(e) => handleAttendeeChange(e)}
                  disabled
                >
                  {map(attendeeType?.attendeeTypes?.data, (type) => (
                    <Option key={type?.id} value={type?.id}>
                      {type?.value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="status">
                <Select placeholder="Status" allowClear>
                  {keys(ATTENDANCE).map((key) => (
                    <Option key={key} value={key}>
                      {ATTENDANCE[key]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div>
              {
                // comment for future use
                /* {isSponsored ? (
                <h4>Add Sponsor Detail</h4>
              ) : (
                <h4>Add Attendee Details</h4>
              )} */
              }
              <h4>Add Attendee Details</h4>
              {
                // comment for future use
                /* <div className="d-flex">
                <Form.Item
                  name="firstName"
                  rules={[required, { whitespace: true, message: 'Required' }]}
                  className="mr-16"
                >
                  <CommonInput placeholder="First Name" allowClear />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  rules={[required, { whitespace: true, message: 'Required' }]}
                  className="mr-16"
                >
                  <CommonInput placeholder="Last Name" allowClear />
                </Form.Item>
                <Form.Item
                  name="phoneNo"
                  rules={[
                    required,
                    () => ({
                      validator(rule, value) {
                        if (value) value.split(' ').join('');
                        const numberPattern = REGEX.PHONE;
                        if (value && !numberPattern.test(value)) {
                          return Promise.reject(
                            new Error('should be a valid phone number'),
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  className="mr-16"
                >
                  <CommonInput allowClear placeholder="9999988888" />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[required, email]}
                  className="mr-16"
                >
                  <CommonInput placeholder="Email" allowClear />
                </Form.Item>
                {selectedEvent && selectedEvent?.isEntryFee && (
                  <Form.Item
                    name="paymentStatus"
                    rules={[required]}
                    className="w-150"
                  >
                    <Select
                      allowClear
                      className="rsvp-select mr-3 "
                      placeholder="Payment Status"
                      onChange={onPaymentStatusChange}
                    >
                      {keys(PAYMENT_STATUS).map((key) => (
                        <Option key={key} value={key}>
                          {PAYMENT_STATUS[key]}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </div> */
              }
              <div className="d-flex select-attendee">
                <Form.Item
                  name="userId"
                  rules={[
                    {
                      required: true,
                      message: 'Please Choose Employee Name!',
                    },
                  ]}
                >
                  <CommonSelect
                    placeholder="Select User"
                    showSearch
                    query={USERS}
                    onSearch={handleSearch}
                    onChange={handleSelectUser}
                    responsePath="users.users"
                    valuePath="id"
                    labelPath="displayName"
                    fetchPolicy="network-only"
                    value={searchStrings}
                    variables={{ filters: { searchStrings } }}
                    useEffectDeps={[searchStrings]}
                    virtual={false}
                    disabled={editData}
                  />
                </Form.Item>
              </div>
              {
                // comment for future use
                /* {isSponsored && (
                <div className="d-flex attendee-details">
                  <Form.Item name="noOfTables" rules={[required, number]}>
                    <Input placeholder="Number of table" allowClear />
                  </Form.Item>
                  <Form.Item name="seatsPerTable" rules={[required, number]}>
                    <Input placeholder="Seats per Table" allowClear />
                  </Form.Item>
                  <Form.Item name="pricePerTable" rules={[required, number]}>
                    <Input
                      placeholder="Price per Table"
                      prefix="$"
                      allowClear
                    />
                  </Form.Item>
                </div>
              )}
              {isSponsored && (
                <div className="d-flex align-items-center  attendee-details">
                  <div className="ant-row ant-form-item mb-0">
                    <h3 className="mb-16">Total: {formatPrice(totalGuest)}</h3>
                  </div>
                </div>
              )} */
              }
              {
                // comment for future use
                /* {(updateData?.attendeeDetails?.spouseName ||
                (selectedAttendeeType &&
                  selectedPaymentStatus !== 'COMP' &&
                  selectedEvent &&
                  selectedEvent?.allowSpouse)) && (
                <Form.Item
                  name="spouseName"
                  fieldKey="spouseName"
                  style={{ width: '215px' }}
                  className="flex-grow-1 mr-2"
                >
                  <Input placeholder="Spouse Name" allowClear />
                </Form.Item>
              )} */
              }
            </div>
            {(maxGuests > 0 || editData?.guests?.length > 0) && (
              <div className="d-flex flex-vertical">
                {
                  // comment for future use
                  /* {isSponsored ? (
                  <h4>{`Guest at Table (Max. ${maxGuests})`}</h4>
                ) : (
                  <h4>{`Add Guest Detail (Max. ${maxGuests})`}</h4>
                )} */
                }
                <h4>{`Add Guest Detail (Max. ${maxGuests})`}</h4>
                <Divider />
                <Form.List name="guests">
                  {(fields, { add, remove }) => (
                    <div>
                      {fields.map((field) => (
                        <div
                          key={field.key}
                          className="d-flex flex-vertical rsvp-guest"
                        >
                          <div className="d-flex">
                            <Form.Item
                              name={[field.name, 'firstName']}
                              key={[field.key, 'firstName']}
                              className="flex-grow-1 mr-5 mb-0"
                              rules={[
                                required,
                                { whitespace: true, message: 'Required' },
                              ]}
                            >
                              <Input placeholder="First Name" allowClear />
                            </Form.Item>
                            <Form.Item
                              name={[field.name, 'lastName']}
                              key={[field.key, 'lastName']}
                              className="flex-grow-1 mr-5 mb-0"
                              rules={[
                                required,
                                { whitespace: true, message: 'Required' },
                              ]}
                            >
                              <Input placeholder="Last Name" allowClear />
                            </Form.Item>
                            <Form.Item
                              name={[field.name, 'phoneNo']}
                              key={[field.key, 'phoneNo']}
                              className="flex-grow-1 mr-5 mb-0"
                              rules={[
                                required,
                                () => ({
                                  validator(rule, value) {
                                    if (value) value.split(' ').join('');
                                    const numberPattern = REGEX.PHONE;
                                    if (value && !numberPattern.test(value)) {
                                      return Promise.reject(
                                        new Error(
                                          'should be a valid phone number',
                                        ),
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <CommonInput
                                allowClear
                                placeholder="9999988888"
                              />
                            </Form.Item>
                            <Form.Item
                              name={[field.name, 'email']}
                              key={[field.key, 'email']}
                              className="flex-grow-1 mr-5 mb-0"
                              rules={[required, email]}
                            >
                              <Input placeholder="Email" allowClear />
                            </Form.Item>
                          </div>
                          <div className="d-none">
                            {!isSponsored && (
                              <>
                                <Form.Item
                                  name={[field.name, 'city']}
                                  key={[field.key, 'city']}
                                  // comment for future use
                                  // rules={[
                                  //   required,
                                  //   { whitespace: true, message: 'Required' },
                                  // ]}
                                  style={{ minWidth: '100px' }}
                                  className="flex-grow-1 mr-5 mb-0"
                                >
                                  <Input placeholder="City" allowClear />
                                </Form.Item>
                                <Form.Item
                                  style={{ minWidth: '100px' }}
                                  className="flex-grow-1 mr-5 mb-0"
                                  name={[field.name, 'state']}
                                  key={[field.key, 'state']}
                                  // comment for future use
                                  // rules={[
                                  //   required,
                                  //   { whitespace: true, message: 'Required' },
                                  // ]}
                                >
                                  <Input placeholder="State" allowClear />
                                </Form.Item>
                                <Form.Item
                                  name={[field.name, 'company']}
                                  key={[field.key, 'company']}
                                  style={{ minWidth: '100px' }}
                                  className="flex-grow-1 mr-5 mb-0"
                                >
                                  <Input
                                    placeholder="Company/Ministry"
                                    allowClear
                                  />
                                </Form.Item>
                                <Form.Item
                                  name={[field.name, 'jobTitle']}
                                  key={[field.key, 'jobTitle']}
                                  style={{ minWidth: '100px' }}
                                  className="flex-grow-1 mr-5 mb-0"
                                  // comment for future use
                                  // rules={[
                                  //   required,
                                  //   { whitespace: true, message: 'Required' },
                                  // ]}
                                >
                                  <Input placeholder="Job title" allowClear />
                                </Form.Item>
                                <Form.Item
                                  name={[field.name, 'masterProgram']}
                                  key={[field.key, 'masterProgram']}
                                  style={{ minWidth: '100px' }}
                                  className="flex-grow-1 mr-5 mb-0"
                                  // comment for future use
                                  // rules={[required]}
                                >
                                  <Select
                                    allowClear
                                    placeholder="Master's Program"
                                  >
                                    <Option value="NEVER">Never</Option>
                                    <Option value="ENROLLED">Enrolled</Option>
                                    <Option value="GRADUATED">Graduated</Option>
                                    <Option value="COMPLETED">Completed</Option>
                                  </Select>
                                </Form.Item>
                              </>
                            )}
                          </div>
                          <div>
                            <CommonButton
                              type="link"
                              danger
                              onClick={() => remove(field.name)}
                              className="rsvp-button-non-sponsored"
                            >
                              <DeleteOutlined />
                            </CommonButton>
                          </div>
                        </div>
                      ))}
                      <Form.Item className="width-fit-content">
                        <div className="form-group row">
                          <div
                            className="col-sm-3"
                            onClick={() => {
                              if (
                                (form.getFieldValue('guests')?.length || 0) <=
                                maxGuests - 1
                              ) {
                                add();
                              } else {
                                toast({
                                  message: `Maximum ${maxGuests} guests allowed`,
                                  type: 'error',
                                });
                              }
                            }}
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                          >
                            + Add another guest
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  )}
                </Form.List>
              </div>
            )}
          </Form>
        )}
      </div>
    </Modal>
  );
};

export default RsvpModal;
