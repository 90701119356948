import {
  InfoCircleOutlined,
  LeftOutlined,
  RightOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Popover,
  Select,
  Space,
  Switch,
  Tooltip,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { debounce, find, flattenDeep, map, some, uniqBy } from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Resizable } from 'react-resizable';
import { Link } from 'react-router-dom';
import api from '../../common/api';
import {
  BACKEND_DATE_FORMAT,
  COLUMN_COLOR,
  DEFAULT_DATE_FORMAT,
  ERROR_MESSAGE,
  LEVEL,
  LIMIT,
  MODULES_KEY,
  PERMISSION_OBJECT,
  PROJECT_COLUMN_TYPE,
  PUSHER_EVENTS,
  ROLE_KEYS,
  ROUTES,
  ROW_COLOR,
  SESSION_EXPIRED,
  SYNC_MESSAGE,
  USER,
  WEEK_FORMAT,
} from '../../common/constants';
import { formValidatorRules, getLeaveTypeLabel } from '../../common/utils';
import CommonSelect from '../../components/CommonSelect';
import CommonTable from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import {
  subscribeToResourceAllocationEvents,
  unsubscribeFromResourceAllocationEvents,
} from '../../components/PusherEvents';
import VerifyPermissions from '../../components/VerifyPermissions';
import history from '../../historyData';

import { ALL_TAGS, DEPARTMENTS } from '../users/graphql/Queries';
import {
  CREATE_RESOURCE_ALLOCATION,
  UPDATE_RESOURCE_ALLOCATION,
} from './graphql/Mutation';
import {
  ACTIVE_PROJECTS,
  PROJECTS,
  RESOURCE_ALLOCATION,
} from './graphql/Queries';
import './resourceAllocation.less';

const { number } = formValidatorRules;
const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e?.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

function ResourceAllocation(stateProps) {
  const {
    location: { state },
  } = stateProps;
  // eslint-disable-next-line no-undef
  const user = JSON.parse(localStorage.getItem(USER));
  const defaultSkip = 0;
  const projectStatus = 'ACTIVE';
  const [resourceData, setResourceData] = useState([]);
  const [searchString, setSearchString] = useState([]);
  const [projectColumns, setProjectColumns] = useState([]);
  const [startDate, setStartDate] = useState(
    state?.startDate
      ? dayjs(state?.startDate)
      : dayjs()?.weekday(1)?.add(1, 'week')?.format(BACKEND_DATE_FORMAT),
  );
  const [endDate, setEndDate] = useState(
    state?.endDate
      ? dayjs(state?.endDate)
      : dayjs()?.weekday(5)?.add(1, 'week')?.format(BACKEND_DATE_FORMAT),
  );
  const [weekPickerValue, setWeekPickerValue] = useState(
    state?.weekPickerValue instanceof dayjs
      ? state.weekPickerValue
      : dayjs().add(1, 'week'),
  );
  const [defaultColumns, setDefaultColumns] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [projectFilterData, setProjectFilterData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const pathName = history?.location?.pathname;
  const [isProjectDataEnd, setIsProjectDataEnd] = useState(false);
  const [skipData, setSkipData] = useState(0);
  const [search, setSearch] = useState('');
  const [tagSearch, setTagSearch] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [departmentFilteredId, setDepartmentFilteredId] = useState();
  const [departmentSearch, setDepartmentSearch] = useState('');
  const [isShow, setIsShow] = useState(false);
  const isCreatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.RESOURCE_ALLOCATION,
    allowedPermissions: [PERMISSION_OBJECT?.create],
  });
  const isProjectReadPermission = VerifyPermissions({
    modulekey: MODULES_KEY?.PROJECTS,
    allowedPermissions: [PERMISSION_OBJECT?.read],
  });
  const isUserReadPermission = VerifyPermissions({
    modulekey: MODULES_KEY?.USERS,
    allowedPermissions: [PERMISSION_OBJECT?.read],
  });

  const [executeQuery, { loading: resourceLoading }] = useLazyQuery(
    RESOURCE_ALLOCATION,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ resourceAllocations }) =>
        setResourceData(uniqBy(resourceAllocations, 'id') ?? []),
      onError: () => {},
    },
  );

  const [executeDepartmentQuery] = useLazyQuery(DEPARTMENTS, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [executeProjectQuery] = useLazyQuery(PROJECTS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setIsProjectDataEnd(
        res?.projectsResourceAllocation?.projects?.length < LIMIT,
      );
      const newData = res?.projectsResourceAllocation?.projects?.map(
        (mappedProject) => ({
          value: mappedProject?.id,
          label: mappedProject?.name,
        }),
      );
      setProjectData((prevData) => uniqBy([...prevData, ...newData], 'value'));
    },
    onError() {},
  });

  useEffect(() => {
    setDefaultColumns([
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        fixed: 'left',
        className: 'link-custom-width',
        render: (value, record) =>
          isUserReadPermission ? (
            <Link
              to={{
                pathname: `${ROUTES?.SETTINGS}${ROUTES?.USERS}/${record?.user?.id}`,
                state: {
                  initialDateFilter: {
                    pathname: pathName,
                    weekPickerValue,
                    startDate,
                    endDate,
                  },
                },
              }}
            >
              <Tooltip
                title={record?.leaves?.map((item, index, array) => {
                  const fromDate = item?.start_date;
                  const toDate = item?.end_date;
                  const isLastItem = index === array?.length - 1;
                  const leaveTypeLabel = getLeaveTypeLabel(item);
                  return (
                    <div
                      key={item?.id}
                      className={`${isLastItem ? '' : 'record-item'}`}
                    >
                      {dayjs(fromDate).isSame(dayjs(toDate)) ? (
                        <p className="m-0 p-0">
                          Date: {dayjs(toDate)?.format(DEFAULT_DATE_FORMAT)}
                          {leaveTypeLabel?.[0]}
                        </p>
                      ) : (
                        <>
                          <p className="m-0 p-0">
                            From: {dayjs(fromDate)?.format(DEFAULT_DATE_FORMAT)}
                            {leaveTypeLabel?.[0]}
                          </p>
                          <p className="m-0 p-0">
                            To: {dayjs(toDate)?.format(DEFAULT_DATE_FORMAT)}
                            {leaveTypeLabel?.[1]}
                          </p>
                        </>
                      )}
                    </div>
                  );
                })}
                placement="top"
              >
                {record?.user?.displayName}
                <p className="name-column">
                  [{`${record?.user?.departmentName}>${record?.user?.jobTitle}`}
                  ]
                </p>
              </Tooltip>
            </Link>
          ) : (
            <Tooltip
              title={record?.leaves?.map((item, index, array) => {
                const fromDate = item?.start_date;
                const toDate = item?.end_date;
                const isLastItem = index === array?.length - 1;
                const leaveTypeLabel = getLeaveTypeLabel(item);

                return (
                  <div
                    key={item?.id}
                    className={`${isLastItem ? '' : 'record-item'}`}
                  >
                    {dayjs(fromDate).isSame(dayjs(toDate)) ? (
                      <p className="m-0 p-0">
                        Date: {dayjs(toDate)?.format(DEFAULT_DATE_FORMAT)}
                        {leaveTypeLabel?.[0]}
                      </p>
                    ) : (
                      <>
                        <p className="m-0 p-0">
                          From: {dayjs(fromDate)?.format(DEFAULT_DATE_FORMAT)}
                          {leaveTypeLabel?.[0]}
                        </p>
                        <p className="m-0 p-0">
                          To: {dayjs(toDate)?.format(DEFAULT_DATE_FORMAT)}
                          {leaveTypeLabel?.[1]}
                        </p>
                      </>
                    )}
                  </div>
                );
              })}
              placement="top"
            >
              {record?.user?.displayName}
              <p className="name-column">
                [{`${record?.user?.departmentName}>${record?.user?.jobTitle}`}]
              </p>
            </Tooltip>
          ),
      },
      {
        title: (
          <Tooltip title="Total" placement="top">
            Total
          </Tooltip>
        ),
        dataIndex: 'totalHours',
        key: 'totalHours',
        fixed: 'left',
        width: 50,
        align: 'center',
      },
      {
        title: (
          <Tooltip title="Planned" placement="top">
            Planned
          </Tooltip>
        ),
        dataIndex: 'plannedHours',
        key: 'plannedHours',
        fixed: 'left',
        width: 65,
        align: 'center',
        render: (text, record) => {
          if (
            record?.totalHours &&
            record?.plannedHours !== record?.totalHours
          ) {
            return (
              <>
                {text}
                <Tooltip
                  title={
                    <div>
                      Planned Hours
                      <br />
                      {record?.plannedHours > record?.totalHours
                        ? 'Exceed'
                        : 'Below'}{' '}
                      Total Hours
                    </div>
                  }
                  placement="top"
                >
                  <span className="planned-hours-tooltips" />
                </Tooltip>
              </>
            );
          }
          return text;
        },
      },
    ]);
  }, [startDate, endDate, selectedItems, searchString, isShow]);

  const [activeProjectColumns, { loading: projectLoading }] = useLazyQuery(
    ACTIVE_PROJECTS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        if (selectedItems?.length > 0) {
          const newData = res?.activeProjects?.map((mappedProject) => ({
            value: mappedProject?.id,
            label: mappedProject?.name,
          }));
          setProjectFilterData(() => uniqBy([...newData], 'value'));
        }
        if (res?.activeProjects) {
          const newProjectColumns = res?.activeProjects?.map((project) => ({
            title: (
              <>
                <Tooltip title={project?.name} placement="top">
                  <span
                    onClick={() => {
                      if (isProjectReadPermission) {
                        history?.push({
                          pathname: `${ROUTES?.SETTINGS}${ROUTES?.PROJECTS}/${project?.id}`,
                          state: {
                            initialDateFilter: {
                              pathname: pathName,
                              weekPickerValue,
                              startDate,
                              endDate,
                            },
                          },
                        });
                      }
                    }}
                    className="pointer"
                  >
                    {project?.name}
                  </span>
                </Tooltip>
              </>
            ),
            dataIndex: project?.id,
            key: project?.id,
            align: 'center',
            editable: true,
            width: 60,
            className: find(project?.tags, (tag) =>
              some(PROJECT_COLUMN_TYPE, { name: tag }),
            ),
          }));
          setProjectColumns(uniqBy(newProjectColumns, 'dataIndex'));
        }
      },
      onError() {},
    },
  );

  const [updateMutation, { loading: updateLoading }] = useMutation(
    UPDATE_RESOURCE_ALLOCATION,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setIsSaved(true);
      },
      onError() {},
    },
  );

  const [createMutation, { loading: createLoading }] = useMutation(
    CREATE_RESOURCE_ALLOCATION,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setIsSaved(true);
      },
      onError() {},
    },
  );

  useEffect(() => {
    activeProjectColumns({
      variables: {
        filters: {
          startDate,
          isShow,
        },
      },
    });

    executeQuery({
      variables: {
        filters: {
          startDate,
          endDate,
          searchString,
        },
      },
    });

    executeDepartmentQuery({
      variables: {
        filters: {
          skip: defaultSkip,
          limit: LIMIT,
        },
      },
    });
    executeProjectQuery({
      variables: {
        filters: {
          skip: defaultSkip,
          limit: LIMIT,
          status: projectStatus,
        },
      },
    });
  }, []);

  useEffect(() => {
    const channel = subscribeToResourceAllocationEvents({
      channelName: PUSHER_EVENTS.RESOURCE_ALLOCATION,
      startDate,
      endDate,
      handlers: {
        handleUpdate: (data) => {
          setResourceData((prev) =>
            prev?.map((record) => {
              if (record?.id === data?.resourceAllocationId) {
                const hoursAllocation = Array?.isArray(record?.hoursAllocation)
                  ? record?.hoursAllocation?.map((allocation) => {
                      if (
                        allocation?.id === data?.resourceProjectAllocationId
                      ) {
                        return { ...allocation, hours: data?.hours };
                      }
                      return allocation;
                    })
                  : [];
                return {
                  ...record,
                  plannedHours: data?.plannedHours,
                  hoursAllocation,
                };
              }
              return record;
            }),
          );
        },

        handleCreate: (data) => {
          setResourceData((prev) =>
            prev?.map((record) => {
              if (record?.id === data?.resourceAllocationId) {
                const hoursAllocation = [
                  ...(record?.hoursAllocation || []),
                  {
                    id: data?.resourceProjectAllocationId,
                    hours: data?.hours,
                    projectId: data?.projectId,
                  },
                ];
                return {
                  ...record,
                  plannedHours: data?.plannedHours,
                  hoursAllocation,
                };
              }
              return record;
            }),
          );
        },
      },
    });

    return () => {
      unsubscribeFromResourceAllocationEvents(channel, startDate, endDate);
    };
  }, [startDate, endDate]);

  const handleRowClassName = (record) => {
    const leave = record?.leaves && map(flattenDeep(record?.leaves), 'status');
    if (
      leave?.includes(ROW_COLOR.APPROVED) ||
      leave?.includes(ROW_COLOR.APPROVAL_IN_PROCESS)
    ) {
      return 'approved-leave-row';
    }
    if (leave?.includes(ROW_COLOR.PENDING)) {
      return 'pending-leave-row';
    }
  };

  const handleDatepicker = (date) => {
    const weekStartDate = dayjs(date)?.weekday(1)?.format(BACKEND_DATE_FORMAT);
    const weekEndDate = dayjs(date)?.weekday(5)?.format(BACKEND_DATE_FORMAT);

    setWeekPickerValue(date);
    setStartDate(weekStartDate);
    setEndDate(weekEndDate);

    const queryVariables = {
      filters: {
        startDate: weekStartDate,
        endDate: weekEndDate,
        searchString: searchString?.length > 0 ? searchString : [],
        tagSearch: selectedItems?.length > 0 ? selectedItems : [],
      },
    };

    executeQuery({
      variables: queryVariables,
    });

    activeProjectColumns({
      variables: {
        filters: {
          startDate: weekStartDate,
          ...(selectedItems?.length > 0 && { searchString: selectedItems }),
          isShow,
        },
      },
    });
  };

  const prevWeek = () => {
    const weekStart = dayjs(weekPickerValue)?.weekday(-6);
    const weekEnd = dayjs(weekPickerValue)?.weekday(-2);
    const formattedStartDate = weekStart?.format(BACKEND_DATE_FORMAT);
    const formattedEndDate = weekEnd?.format(BACKEND_DATE_FORMAT);
    setWeekPickerValue(weekStart);
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    setProjectColumns([]);
    setDefaultColumns([]);
    executeQuery({
      variables: {
        filters: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          searchString: searchString?.length > 0 ? searchString : [],
          tagSearch: selectedItems?.length > 0 ? selectedItems : [],
        },
      },
    });
    activeProjectColumns({
      variables: {
        filters: {
          startDate: formattedStartDate,
          ...(selectedItems?.length > 0 && { searchString: selectedItems }),
          isShow,
        },
      },
    });
  };

  const nextWeek = () => {
    const newWeekStart = dayjs(weekPickerValue)?.weekday(8);
    const newWeekEnd = dayjs(weekPickerValue)?.weekday(12);
    const formattedNewStartDate = newWeekStart?.format(BACKEND_DATE_FORMAT);
    const formattedNewEndDate = newWeekEnd?.format(BACKEND_DATE_FORMAT);
    setWeekPickerValue(newWeekStart);
    setStartDate(formattedNewStartDate);
    setEndDate(formattedNewEndDate);
    setProjectColumns([]);
    setDefaultColumns([]);
    executeQuery({
      variables: {
        filters: {
          startDate: formattedNewStartDate,
          endDate: formattedNewEndDate,
          searchString: searchString?.length > 0 ? searchString : [],
          tagSearch: selectedItems?.length > 0 ? selectedItems : [],
        },
      },
    });
    activeProjectColumns({
      variables: {
        filters: {
          startDate: formattedNewStartDate,
          ...(selectedItems?.length > 0 && { searchString: selectedItems }),
          isShow,
        },
      },
    });
  };

  const handleDepartment = (value) => {
    setDepartmentFilteredId(value);
    setSearchString(value?.toString()?.trim()?.split(','));
    setDepartmentSearch('');
    const formattedStartDate = weekPickerValue
      ?.weekday(1)
      ?.format(BACKEND_DATE_FORMAT);
    const formattedEndDate = weekPickerValue
      ?.weekday(5)
      ?.format(BACKEND_DATE_FORMAT);
    setProjectColumns([]);
    setDefaultColumns([]);
    executeQuery({
      variables: {
        filters: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          searchString:
            value?.length > 0 ? value?.toString()?.trim()?.split(',') : [],
          tagSearch: selectedItems?.length > 0 ? selectedItems : [],
        },
      },
    });
    activeProjectColumns({
      variables: {
        filters: {
          startDate: formattedStartDate,
          ...(selectedItems?.length > 0 && { searchString: selectedItems }),
          isShow,
        },
      },
    });
  };
  const handleDepartmentSearch = debounce((value) => {
    setDepartmentSearch(value);
  }, 500);

  const handlePopupScroll = async (e) => {
    const nearBottom =
      e?.target?.scrollTop + e?.target?.clientHeight ===
      e?.target?.scrollHeight;
    if (nearBottom && !isProjectDataEnd) {
      const newSkip = skipData + LIMIT;
      await executeProjectQuery({
        variables: {
          filters: {
            skip: newSkip,
            limit: LIMIT,
            status: projectStatus,
          },
        },
      });
      setSkipData(newSkip);
    }
  };

  const handleSearchData = debounce((value) => {
    executeProjectQuery({
      variables: {
        filters: {
          skip: defaultSkip,
          limit: LIMIT,
          searchString: value,
          status: projectStatus,
        },
      },
    });
  }, 700);

  const handleTagChange = (selectedTags) => {
    setSearch('');
    setTagSearch('');
    setSelectedItems(selectedTags);
    setProjectColumns([]);
    setDefaultColumns([]);
    executeQuery({
      variables: {
        filters: {
          startDate,
          endDate,
          searchString: searchString?.length > 0 ? searchString : [],
          tagSearch: selectedTags?.length > 0 ? selectedTags : [],
        },
      },
    });
    activeProjectColumns({
      variables: {
        filters: {
          startDate,
          ...(selectedTags?.length > 0 && { searchString: selectedTags }),
          isShow,
        },
      },
    });
  };

  const debouncedUpdateValue = useCallback(
    debounce((newValue) => {
      setSkipData(0);
      setTagSearch(newValue?.toLowerCase());
    }, 500),
    [],
  );

  const updateSearchValue = (newValue) => {
    setSearch(newValue?.toLowerCase());
  };

  const handleTagSearch = (searchText) => {
    debouncedUpdateValue(searchText);
    updateSearchValue(searchText);
  };

  const handleProjectSelect = (value) => {
    setSelectedProjects(value);
    setSearchString([]);
    executeProjectQuery({
      variables: {
        filters: {
          skip: defaultSkip,
          limit: LIMIT,
          status: projectStatus,
        },
      },
    });
  };

  const renderMaxTag = (omittedValues) => {
    if (omittedValues?.length === 0) {
      return null;
    }

    return <span>+ {omittedValues?.length} more...</span>;
  };

  const customWeekStartEndFormat = (value) =>
    `${dayjs(value)?.weekday(1)?.format(WEEK_FORMAT)} - ${dayjs(value)
      ?.weekday(5)
      ?.format(WEEK_FORMAT)}`;

  const handleBeforeUnload = () => {
    history.push({
      pathname: history.location.pathname,
      state: null,
    });
  };
  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (defaultColumns?.length > 0) {
      setDefaultColumns([...defaultColumns, ...projectColumns]);
    }
  }, [projectColumns]);

  useEffect(() => {
    if (defaultColumns?.length > 0) {
      setDefaultColumns([defaultColumns, projectColumns]);
    }
  }, [selectedItems]);

  const filteredColumns = useMemo(
    () =>
      defaultColumns?.filter((col) => {
        if (!col?.editable) return true;
        if (selectedProjects?.length === 0) return true;
        return (
          selectedProjects?.includes(col?.dataIndex) || col?.fixed === 'left'
        );
      }),
    [selectedProjects, defaultColumns],
  );

  const EditableContext = createContext(null);
  const EditableRow = ({ index, ...props }) => {
    const [form] = Form?.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const newRecord = record?.hoursAllocation;
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    const projectHours = newRecord?.map((iterator) => {
      const hour = { [iterator?.projectId]: iterator?.hours };
      return hour;
    });
    useEffect(() => {
      if (editing && weekPickerValue?.weekday(1) > dayjs()?.weekday(5)) {
        inputRef?.current?.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form?.setFieldsValue({
        [dataIndex]: record?.[dataIndex],
      });
    };

    const save = async (event) => {
      try {
        const hoursValue = parseInt(event?.target?.value, 10);
        const existingRecord = newRecord?.find(
          (item) => item?.projectId === dataIndex,
        );
        const isCellMatched = newRecord?.some(
          (item) => item?.projectId === dataIndex,
        );
        if (isCellMatched && newRecord !== null) {
          const updatedRecord = {
            ...existingRecord,
            hours: hoursValue,
          };
          setIsSaved(false);
          await updateMutation({
            variables: {
              input: {
                hours: updatedRecord?.hours,
                id: updatedRecord?.id,
              },
            },
          });
        } else {
          const createdRecord = {
            projectId: dataIndex,
            projectName: title,
            hours: hoursValue,
          };
          setIsSaved(false);
          await createMutation({
            variables: {
              input: {
                hours: createdRecord?.hours,
                projectId: createdRecord?.projectId,
                resourceAllocationId: record?.id,
              },
            },
          });
        }
        toggleEdit();
      } catch (errInfo) {
        // eslint-disable-next-line no-console
        console?.error('Save failed:', errInfo);
      }
    };

    let childNode = children || '';
    if (editable) {
      childNode = editing ? (
        <Form.Item name="cellEdit" rules={[number]}>
          <div className="in-col-edit">
            <Input
              defaultValue={
                record?.hoursAllocation?.find(
                  (item) => item?.projectId === dataIndex,
                )?.hours
              }
              maxLength={2}
              ref={inputRef}
              onPressEnter={(event) => save(event)}
              onBlur={(event) => save(event)}
              bordered={false}
              disabled={
                weekPickerValue?.isBefore(dayjs().weekday(1), 'day') ||
                !isCreatePermission
              }
            />
          </div>
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
          {projectHours?.map((object) => {
            const value = object?.[dataIndex] === 0 ? '' : object?.[dataIndex];
            return dataIndex === Object?.keys(object)?.[0] ? value : children;
          })}
        </div>
      );
    }
    return (
      <td {...restProps} id={dataIndex}>
        {childNode}
      </td>
    );
  };

  const components = {
    header: {
      cell: ResizableTitle,
    },
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleResize = useCallback(
    (index) =>
      (e, { size }) => {
        const nextColumns = [...defaultColumns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size?.width,
        };
        setDefaultColumns(nextColumns);
      },
    [defaultColumns],
  );

  const highlightColumn = (dataIndex, color) => {
    // eslint-disable-next-line no-undef
    const inputs = document?.querySelectorAll(`[id^="${dataIndex}"]`);
    if (inputs) {
      inputs?.forEach((input) => {
        // eslint-disable-next-line no-param-reassign
        input.style.backgroundColor = color;
      });
    }
  };

  const columns = filteredColumns?.map((col, index) => {
    if (!col?.editable) {
      return col;
    }
    let isFocused = false;
    return {
      ...col,
      onHeaderCell: (column) => ({
        width: column?.width,
        onResize: handleResize(index),
      }),
      onCell: (record) => ({
        record,
        editable: col?.editable,
        dataIndex: col?.dataIndex,
        title: col?.title,
        onMouseEnter: () => {
          if (!isFocused) {
            highlightColumn(col?.dataIndex, COLUMN_COLOR);
          }
        },
        onMouseLeave: () => {
          if (!isFocused) {
            highlightColumn(col?.dataIndex, '');
          }
        },
        onFocus: () => {
          highlightColumn(col?.dataIndex, COLUMN_COLOR);
          isFocused = true;
        },
        onBlur: () => {
          highlightColumn(col?.dataIndex, '');
          isFocused = false;
        },
      }),
    };
  });

  const handleSync = async () => {
    const syncUrls = [
      process.env.REACT_APP_SYNC_LEAVES_URL,
      process.env.REACT_APP_SYNC_DASHBOARD_LEAVES_URL,
      process.env.REACT_APP_SYNC_USERS_URL,
      process.env.REACT_APP_SYNC_DEPARTMENT_URL,
    ];
    try {
      await Promise?.all(
        syncUrls?.map(async (url) => {
          await api?.post(url);
        }),
      );
      message?.destroy();
      message?.success(SYNC_MESSAGE);
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.extensions?.code === SESSION_EXPIRED
      ) {
        message?.destroy();
        message?.error(ERROR_MESSAGE);
        history?.replace({
          pathname: ROUTES?.AUTHENTICATION,
          // eslint-disable-next-line no-undef
          state: { from: window?.location?.pathname },
        });
      }
    }
  };

  const handleToggleSwitch = (checked) => {
    setIsShow(checked);
    setProjectColumns([]);
    setDefaultColumns([]);
    activeProjectColumns({
      variables: {
        filters: {
          startDate,
          ...(selectedItems?.length > 0 && { searchString: selectedItems }),
          isShow: checked,
        },
      },
    });
  };

  const RenderTable = useMemo(
    () =>
      resourceLoading || projectLoading ? (
        <LoaderComponent size="default" />
      ) : (
        <CommonTable
          size="small"
          components={components}
          columns={columns}
          dataSource={resourceData}
          rowKey={(obj) => obj?.id}
          bordered
          scroll={{
            y: '100%',
          }}
          rowClassName={handleRowClassName}
          className="resource-allocation-table"
        />
      ),
    [
      resourceData,
      departmentFilteredId,
      selectedItems,
      selectedProjects,
      defaultColumns,
    ],
  );

  return (
    <div className="table-card-page">
      <div className="resource-allocation">
        <Card
          className="ant-body-scroll"
          title={
            <span>
              Resource Allocation{' '}
              {(updateLoading || createLoading) && (
                <span className="saving-message">Saving...</span>
              )}
              {isSaved && <span className="saved-message">Saved</span>}
            </span>
          }
          extra={
            <>
              <div className="resourceAllocation-sidebar">
                <div className="resourceAllocation-filter-right">
                  <div className="resourceAllocation-filter">
                    <div className="project-color-info">
                      <Popover
                        placement="rightTop"
                        title="Project Color Info"
                        content={
                          <Space direction="vertical">
                            {Object.entries(PROJECT_COLUMN_TYPE)?.map(
                              ([key, type]) => (
                                <Badge
                                  key={key}
                                  className="project-info-badge"
                                  color={type?.color}
                                  text={type?.name}
                                />
                              ),
                            )}
                          </Space>
                        }
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                    <div className="resourceAllocation-date-picker">
                      <div className="d-flex">
                        <LeftOutlined onClick={prevWeek} />
                        <DatePicker
                          suffixIcon={null}
                          value={weekPickerValue}
                          onChange={handleDatepicker}
                          format={customWeekStartEndFormat}
                          picker="week"
                          bordered={false}
                        />
                        <RightOutlined onClick={nextWeek} />
                      </div>
                    </div>
                    {([ROLE_KEYS?.SUPER_ADMIN, ROLE_KEYS?.ADMIN]?.includes(
                      user?.role,
                    ) ||
                      [LEVEL?.L1, LEVEL?.L2]?.includes(user?.level?.name)) && (
                      <Tooltip
                        placement="bottom"
                        title={
                          <span>
                            Users, Department, Leaves, Dashboard-Leaves Sync
                          </span>
                        }
                      >
                        <Button onClick={handleSync}>
                          <SyncOutlined />
                        </Button>
                      </Tooltip>
                    )}
                    <span className="toggle-project-name">
                      <Switch
                        checkedChildren="Planned"
                        unCheckedChildren="All"
                        defaultChecked={false}
                        onChange={handleToggleSwitch}
                      />
                    </span>
                    <span className="visible-project-name">
                      <Select
                        mode="multiple"
                        placeholder="Select Project Columns"
                        className="visible-project-columns"
                        onChange={handleProjectSelect}
                        maxTagCount={3}
                        maxTagPlaceholder={renderMaxTag}
                        options={
                          selectedItems?.length > 0
                            ? projectFilterData
                            : projectData
                        }
                        allowClear
                        filterOption={(inputValue, option) =>
                          option?.label
                            ?.toLowerCase()
                            ?.includes(inputValue?.toLowerCase())
                        }
                        onPopupScroll={handlePopupScroll}
                        onSearch={(e) => {
                          handleSearchData(e);
                          setSkipData(0);
                        }}
                        virtual={false}
                      />
                    </span>
                    <span className="resource-allocation-select">
                      <>
                        <CommonSelect
                          mode="tags"
                          placeholder="Select Name, Department, Designation"
                          className="resource-allocation-search"
                          query={DEPARTMENTS}
                          onChange={handleDepartment}
                          onSearch={handleDepartmentSearch}
                          variables={{ search: departmentSearch }}
                          useEffectDeps={[departmentSearch]}
                          responsePath="departments.departments"
                          valuePath="name"
                          labelPath="name"
                          fetchPolicy="network-only"
                          maxTagCount={3}
                          maxTagPlaceholder={renderMaxTag}
                          allowClear
                          onClear={() => setSearchString([])}
                          value={departmentFilteredId}
                          virtual={false}
                        />
                      </>
                    </span>
                    <span className="resource-allocation-select">
                      <CommonSelect
                        mode="tags"
                        placeholder="Search Tags"
                        className="resource-allocation-tags"
                        allowClear
                        query={ALL_TAGS}
                        onChange={handleTagChange}
                        onSearch={(searchText) => {
                          handleTagSearch(searchText);
                        }}
                        variables={{
                          searchString: tagSearch,
                        }}
                        useEffectDeps={[tagSearch]}
                        responsePath="searchTags.tags"
                        valuePath="name"
                        labelPath="name"
                        fetchPolicy="network-only"
                        maxTagCount={3}
                        maxTagPlaceholder={renderMaxTag}
                        searchValue={search}
                        virtual={false}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </>
          }
        >
          <div className="card-body-wrapper">{RenderTable}</div>
        </Card>
      </div>
    </div>
  );
}

export default ResourceAllocation;
