import { useMutation } from '@apollo/client';
import { Button, DatePicker, Form, Input, Select, Switch, Table } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { BACKEND_DATE_FORMAT, USER } from '../../common/constants';
import CommonSelect from '../../components/CommonSelect';
import { INCIDENT_MASTERS } from '../incidentMaster/graphql/Queries';
import { CREATE_INCIDENT_REPORT_ADMIN } from './graphql/Mutations';
import { SEARCH_USERS } from './graphql/Queries';

const { Option } = Select;
const { TextArea } = Input;

const CreateIncident = () => {
  const initialData = Array.from({ length: 10 }, (_, index) => ({
    key: index.toString(),
    reportingForUserId: '',
    date: '',
    projectName: '',
    severity: '',
    description: '',
    isSaved: false,
    isDiscussionRequired: false,
  }));
  // eslint-disable-next-line no-undef
  const user = JSON.parse(localStorage.getItem(USER));

  const [dataSource, setDataSource] = useState(initialData);
  const [form] = Form.useForm();
  const [userSearch, setUserSearch] = useState();

  const { dispatch = () => {}, state: { showPrompt = false } = {} } =
    useContext(AppContext) || {};

  const [createIncidentReportAdmin] = useMutation(
    CREATE_INCIDENT_REPORT_ADMIN,
    {
      onCompleted: () => {
        dispatch({
          type: 'SET_SHOW_PROMPT',
          data: false,
        });
      },
      onError() {},
    },
  );

  const saveRow = async (key) => {
    try {
      await form.validateFields([
        `reportingForUserId-${key}`,
        `date-${key}`,
        `projectName-${key}`,
        `severity-${key}`,
        `description-${key}`,
        `isDiscussionRequired-${key}`,
      ]);
      const row = dataSource.find((item) => item.key === key);
      const updatedRow = {
        ...row,
        reportingForUserId: form.getFieldValue(`reportingForUserId-${key}`),
        date: form.getFieldValue(`date-${key}`),
        projectName: form.getFieldValue(`projectName-${key}`),
        severity: form.getFieldValue(`severity-${key}`),
        description: form.getFieldValue(`description-${key}`),
        isSaved: true,
        isDiscussionRequired: form.getFieldValue(`isDiscussionRequired-${key}`),
        incidentMasterId: form.getFieldValue(`incidentMasterId-${key}`),
      };

      const res = await createIncidentReportAdmin({
        variables: {
          data: {
            date: dayjs(updatedRow?.date).format(BACKEND_DATE_FORMAT),
            description: updatedRow?.description,
            projectName: updatedRow?.projectName,
            reportingForUserId: [...updatedRow?.reportingForUserId],
            severity: updatedRow?.severity,
            isDiscussionRequired: updatedRow?.isDiscussionRequired,
            incidentMasterId: updatedRow?.incidentMasterId,
          },
        },
      });

      if (res?.data) {
        setDataSource((prevData) =>
          prevData?.map((item) =>
            item?.key === updatedRow?.key ? updatedRow : item,
          ),
        );
      }
    } catch (error) {
      return error;
    }
  };

  const handleUserSearch = debounce((value) => {
    setUserSearch(value);
  }, 500);

  const handleAddRow = () => {
    const newRow = {
      key: dataSource?.length?.toString(),
      reportingForUserId: '',
      date: '',
      projectName: '',
      severity: '',
      description: '',
      isSaved: false,
      isDiscussionRequired: false,
    };
    setDataSource([...dataSource, newRow]);
  };

  const disableFutureDates = (current) =>
    current && current > dayjs().endOf('day');

  const columns = [
    {
      title: 'Raised For',
      dataIndex: 'reportingForUserId',
      width: '15%',
      render: (_, record) => (
        <Form.Item
          name={`reportingForUserId-${record.key}`}
          rules={[{ required: true, message: 'Please Enter Raised For!' }]}
        >
          <CommonSelect
            placeholder="Select User"
            showSearch
            query={SEARCH_USERS}
            onSearch={handleUserSearch}
            mode="multiple"
            responsePath="searchUsers"
            valuePath="id"
            labelPath="displayName"
            fetchPolicy="network-only"
            variables={{
              searchStrings: userSearch,
              idsToExclude: [user?.id],
            }}
            useEffectDeps={[userSearch]}
            virtual={false}
            disabled={record?.isSaved}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Incident Date',
      dataIndex: 'date',
      width: '10%',
      render: (_, record) => (
        <Form.Item
          name={`date-${record.key}`}
          rules={[{ required: true, message: 'Please Enter Incident Date!' }]}
        >
          <DatePicker
            disabledDate={disableFutureDates}
            className="incident-form"
            disabled={record?.isSaved}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'incidentMasterId',
      width: '15%',
      render: (_, record) => (
        <Form.Item
          name={`incidentMasterId-${record.key}`}
          rules={[
            { required: true, message: 'Please Select Incident Master!' },
          ]}
        >
          <CommonSelect
            placeholder="Select Category"
            showSearch
            query={INCIDENT_MASTERS}
            onSearch={handleUserSearch}
            responsePath="incidentMasters.data"
            valuePath="id"
            labelPath="name"
            fetchPolicy="network-only"
            variables={{
              filters: { search: userSearch, statusFilter: 'ACTIVE' },
            }}
            useEffectDeps={[userSearch]}
            virtual={false}
            disabled={record?.isSaved}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Project/Task Name',
      dataIndex: 'projectName',
      render: (_, record) => (
        <Form.Item
          name={`projectName-${record.key}`}
          rules={[
            { required: true, message: 'Please Enter Project/Task Name!' },
          ]}
        >
          <Input placeholder="Enter Text Here" disabled={record?.isSaved} />
        </Form.Item>
      ),
    },
    {
      title: 'Severity',
      dataIndex: 'severity',
      render: (_, record) => (
        <Form.Item
          name={`severity-${record.key}`}
          rules={[{ required: true, message: 'Please Select Severity!' }]}
        >
          <Select placeholder="Select Severity" disabled={record?.isSaved}>
            <Option value="LOW">Low</Option>
            <Option value="MEDIUM">Medium</Option>
            <Option value="HIGH">High</Option>
          </Select>
        </Form.Item>
      ),
    },
    {
      title: 'Describe The Incident',
      dataIndex: 'description',
      render: (_, record) => (
        <Form.Item
          name={`description-${record.key}`}
          className="tbl-description-input"
          rules={[
            { required: true, message: 'Please Enter Description!' },
            { min: 100, message: 'Minimum 100 Characters Are Required!' },
            { max: 5000, message: 'Maximum 5000 Characters Are Allowed!' },
          ]}
        >
          <TextArea
            showCount
            maxLength={5000}
            placeholder="Enter Text Here"
            disabled={record?.isSaved}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Is Discussion Required?',
      dataIndex: 'isDiscussionRequired',
      width: '5%',
      render: (_, record) => (
        <Form.Item name={`isDiscussionRequired-${record?.key}`}>
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            disabled={record?.isSaved}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Action',
      render: (_, record) => (
        <Form.Item>
          <Button
            type="primary"
            size="small"
            disabled={record?.isSaved}
            onClick={() => saveRow(record?.key)}
          >
            Save
          </Button>
        </Form.Item>
      ),
    },
  ];

  return (
    <div className="create-incident-container">
      <Form
        form={form}
        component={false}
        onFieldsChange={() => {
          if (!showPrompt) {
            dispatch({
              type: 'SET_SHOW_PROMPT',
              data: true,
            });
          }
        }}
      >
        <Table
          className="incident-table"
          dataSource={dataSource}
          columns={columns}
          rowClassName="editable-row"
          pagination={false}
        />
      </Form>
      <div className="d-flex justify-center">
        <Button
          onClick={handleAddRow}
          type="primary"
          className="d-flex justify-center mt-16"
        >
          Add Row
        </Button>
      </div>
    </div>
  );
};

export default CreateIncident;
