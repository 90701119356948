import {
  DollarOutlined,
  FileSyncOutlined,
  FilterFilled,
  PlusOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Checkbox,
  Form,
  Radio,
  Space,
  Switch,
  Tooltip,
  message,
} from 'antd';
import { debounce, isBoolean, omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { GoogleChat, GoogleMeet, UserResume } from '../../assets/svg';
import api from '../../common/api';
import {
  ADD_USER_CHAT_OR_SCRUM_REF_TYPE,
  ADD_USER_TO_CHAT_OR_MEET,
  BILLING_TYPE,
  ERROR_MESSAGE,
  LEVEL,
  LIMIT,
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROLE_KEYS,
  ROUTES,
  ROUTES_MODULES_KEY,
  SESSION_EXPIRED,
  SYNC_MESSAGE,
  USER,
} from '../../common/constants';
import CommonSelect from '../../components/CommonSelect';
import CommonTable from '../../components/CommonTable';
import VerifyPermissions from '../../components/VerifyPermissions';
import {
  ADD_USER_TO_CHAT_SPACES,
  ADD_USER_TO_MORNING_CALL,
} from './graphql/Mutations';
import {
  CHECK_AUTH_FOR_CALENDER,
  CHECK_AUTH_FOR_CHAT,
  DEPARTMENTS,
  DESIGNATIONS,
  GET_CALENDER_AUTH_URL,
  GET_CHAT_AUTH_URL,
  USERS,
} from './graphql/Queries';

const initialFinalFilter = {
  name: '',
  department: '',
  designation: '',
  billable: '',
};

function Users(props) {
  const {
    location: { state },
  } = props;
  const history = useHistory();
  // eslint-disable-next-line no-undef
  const user = JSON.parse(localStorage?.getItem(USER));
  const [form] = Form?.useForm();
  const [paginationFilter, setPaginationFilter] = useState({
    currentPage: state?.currentPage || 1,
    pageSize: state?.pageSize || LIMIT,
  });
  const [initialFilter, setInitialFilter] = useState({
    skip: state?.skip || 0,
    searchStrings: state?.searchStrings || [],
    sortOrder: state?.sortOrder || 'ASC',
    sortField: state?.sortField || 'displayName',
    designation: state?.designation || null,
    departmentId: state?.departmentId || null,
    showInactiveUsers: state?.showInactiveUsers || true,
  });
  const [filterVisible, setFilterVisible] = useState({
    nameFilterVisible: false,
    designationFilterVisible: false,
    departmentFilterVisible: false,
    billableFilterVisible: false,
  });
  const activeSort = initialFilter?.sortOrder === 'DESC' ? 'descend' : 'ascend';
  const [checked, setChecked] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState({});
  const [finalFilter, setFinalFilter] = useState(initialFinalFilter);
  const [departmentSearch, setDepartmentSearch] = useState('');
  const [designationSearch, setDesignationSearch] = useState('');
  const [designationDataSearch, setDesignationDataSearch] = useState('');
  const isUserCreatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.USERS,
    allowedPermissions: [PERMISSION_OBJECT?.create],
  });

  const [executeQuery, { loading, data }] = useLazyQuery(USERS, {
    fetchPolicy: 'network-only',
    onError() {},
  });
  useEffect(() => {
    executeQuery({
      variables: {
        filters: {
          ...initialFilter,
          limit: paginationFilter?.pageSize,
        },
      },
    });
  }, []);

  const [addUserToChatSpace] = useMutation(ADD_USER_TO_CHAT_SPACES, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [addUserToMorningCall] = useMutation(ADD_USER_TO_MORNING_CALL, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [checkAuthForChat] = useLazyQuery(CHECK_AUTH_FOR_CHAT, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [checkAuthForCalendar] = useLazyQuery(CHECK_AUTH_FOR_CALENDER, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [getChatAuthUrl] = useLazyQuery(GET_CHAT_AUTH_URL, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [getCalenderAuthUrl] = useLazyQuery(GET_CALENDER_AUTH_URL, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const handleSearch = debounce((value) => {
    const newPaginationFilter = { ...paginationFilter, currentPage: 1 };
    const newInitialFilter = {
      ...initialFilter,
      skip: 0,
      searchStrings:
        value?.length > 0 ? value?.toString()?.trim()?.split(',') : [],
    };
    setPaginationFilter(newPaginationFilter);
    setInitialFilter(newInitialFilter);
    setDesignationDataSearch('');
    executeQuery({
      variables: {
        filters: {
          ...newInitialFilter,
          skip: 0,
          limit: paginationFilter?.pageSize,
          searchStrings:
            value?.length > 0 ? value?.toString()?.trim()?.split(',') : [],
        },
      },
    });
  }, 700);

  const onChange = (pagination, filters, sorter) => {
    const { current: currentPage, pageSize } = pagination;
    const { order, field } = sorter;
    const filter = {
      skip: currentPage ? (currentPage - 1) * pageSize : 0,
      sortOrder: order === 'descend' ? 'DESC' : 'ASC',
      sortField: field,
    };
    setInitialFilter({ ...initialFilter, ...filter });
    setPaginationFilter({ ...paginationFilter, currentPage, pageSize });
    executeQuery({
      variables: {
        filters: {
          ...initialFilter,
          skip: filter?.skip,
          limit: pageSize,
          sortField: initialFilter.sortField || filter?.sortField,
          sortOrder: filter?.sortOrder,
        },
      },
    });
  };

  const handleReset = (prop) => {
    const updatedFinalFilter = {
      ...finalFilter,
      name: prop === 'name' ? '' : finalFilter?.name,
      designation: prop === 'designation' ? '' : finalFilter?.designation,
      department: prop === 'department' ? '' : finalFilter?.department,
      billable: prop === BILLING_TYPE?.BILLABLE ? '' : finalFilter?.billable,
    };
    const resetFilters = {
      ...initialFilter,
      limit: paginationFilter?.pageSize,
      sortField: prop === 'name' ? 'displayName' : initialFilter?.sortField,
      departmentId: prop === 'department' ? null : initialFilter?.departmentId,
      designation: prop === 'designation' ? null : initialFilter?.designation,
    };
    let resetBillable;
    if (prop === BILLING_TYPE?.BILLABLE) {
      form?.resetFields();
      resetBillable = omit(initialFilter, BILLING_TYPE?.BILLABLE);
      executeQuery({
        variables: {
          filters: { ...resetBillable, limit: paginationFilter?.pageSize },
        },
      });
    } else {
      executeQuery({
        variables: {
          filters: resetFilters,
        },
      });
    }
    setFinalFilter(updatedFinalFilter);
  };

  const handleBillableFilter = (e) => {
    setInitialFilter({
      ...initialFilter,
      billable: e?.target?.value,
    });
  };
  const handleNameFilter = (e) => {
    setChecked(e?.target?.checked);
    if (e?.target?.checked) {
      setInitialFilter({
        ...initialFilter,
        sortField: e?.target?.name,
      });
    }
  };
  const handleDesignation = (value) => {
    setInitialFilter({
      ...initialFilter,
      designation: value,
    });
    setDesignationSearch('');
  };

  const handleDesignationSearch = debounce((value) => {
    setDesignationSearch(value);
  }, 500);

  const handleDesignationDataSearch = debounce((value) => {
    setDesignationDataSearch(value);
  }, 500);

  const handleDepartment = (value) => {
    setInitialFilter({
      ...initialFilter,
      departmentId: value,
    });
    setDepartmentSearch('');
  };

  const handleDepartmentSearch = debounce((value) => {
    setDepartmentSearch(value);
  }, 500);

  const handleUserResume = (recordId) => {
    // eslint-disable-next-line no-undef
    window.open(`${ROUTES_MODULES_KEY?.USERS}/${recordId}${ROUTES?.RESUME}`);
  };

  const handleFilter = () => {
    const { pageSize } = paginationFilter;
    const newPaginationFilter = {
      ...paginationFilter,
      currentPage: 1,
      pageSize,
    };
    const newInitialFilter = { ...initialFilter, skip: 0 };
    const newFinalFilter = {
      name: initialFilter?.sortField,
      designation: initialFilter?.designation,
      department: initialFilter?.departmentId,
      billable: initialFilter?.billable,
    };
    setPaginationFilter(newPaginationFilter);
    setInitialFilter(newInitialFilter);
    setFinalFilter(newFinalFilter);
    const resetFilters = {
      ...newInitialFilter,
      limit: pageSize,
    };
    setFilterVisible({
      nameFilterVisible: false,
      designationFilterVisible: false,
      departmentFilterVisible: false,
      billableFilterVisible: false,
    });
    executeQuery({
      variables: {
        filters: resetFilters,
      },
    });
  };

  const getNameFilterProps = (dataIndex) => ({
    filterDropdownOpen: filterVisible?.nameFilterVisible,
    onFilterDropdownOpenChange: (visible) =>
      setFilterVisible({
        ...filterVisible,
        nameFilterVisible: visible,
      }),
    filterDropdown: () => (
      <div className="columnFilter" onKeyDown={(e) => e?.stopPropagation()}>
        <Space direction="vertical">
          <Checkbox
            name="lastName"
            checked={checked}
            onChange={handleNameFilter}
          >
            {dataIndex}
          </Checkbox>
          <Space direction="horizontal">
            <Button size="small" onClick={handleFilter}>
              Apply
            </Button>
            <Button
              danger
              size="small"
              onClick={() => {
                handleReset('name');
                setChecked(false);
                setInitialFilter({
                  ...initialFilter,
                  sortField: 'displayName',
                });
                setFilterVisible({
                  ...filterVisible,
                  nameFilterVisible: false,
                });
              }}
            >
              Reset
            </Button>
          </Space>
        </Space>
      </div>
    ),
    filterIcon: () => (
      <FilterFilled
        className={
          finalFilter?.name === 'lastName' ||
          initialFilter?.sortField === 'lastName'
            ? 'filter-filled-data'
            : ''
        }
      />
    ),
  });

  const getJobTitleFilterProps = () => ({
    filterDropdownOpen: filterVisible?.designationFilterVisible,
    onFilterDropdownOpenChange: (visible) =>
      setFilterVisible({
        ...filterVisible,
        designationFilterVisible: visible,
      }),
    filterSearch: true,
    filterDropdown: () => (
      <div className="columnFilter" onKeyDown={(e) => e?.stopPropagation()}>
        <Space direction="vertical">
          <CommonSelect
            placeholder="Select Designation"
            showSearch
            query={DESIGNATIONS}
            onChange={handleDesignation}
            onSearch={handleDesignationSearch}
            variables={{ searchString: designationSearch }}
            useEffectDeps={[designationSearch]}
            responsePath="designations.designations"
            valuePath="jobTitle"
            labelPath="jobTitle"
            fetchPolicy="network-only"
            onClear={() =>
              setInitialFilter({
                ...initialFilter,
                searchStrings: [],
              })
            }
            value={initialFilter?.designation}
            virtual={false}
          />
          <Space direction="horizontal">
            <Button size="small" onClick={handleFilter}>
              Apply
            </Button>
            <Button
              danger
              size="small"
              onClick={() => {
                handleReset('designation');
                setInitialFilter({
                  ...initialFilter,
                  designation: null,
                });
                setFilterVisible({
                  ...filterVisible,
                  designationFilterVisible: false,
                });
              }}
            >
              Reset
            </Button>
          </Space>
        </Space>
      </div>
    ),
    filterIcon: () => (
      <FilterFilled
        className={
          finalFilter?.designation || initialFilter?.designation
            ? 'filter-filled-data'
            : ''
        }
      />
    ),
  });

  const getDepartmentFilterProps = () => ({
    filterDropdownOpen: filterVisible?.departmentFilterVisible,
    onFilterDropdownOpenChange: (visible) =>
      setFilterVisible({
        ...filterVisible,
        departmentFilterVisible: visible,
      }),
    filterSearch: true,
    filterDropdown: () => (
      <div className="columnFilter" onKeyDown={(e) => e?.stopPropagation()}>
        <Space direction="vertical">
          <CommonSelect
            placeholder="Select Department"
            showSearch
            query={DEPARTMENTS}
            onChange={handleDepartment}
            onSearch={handleDepartmentSearch}
            variables={{ search: departmentSearch }}
            useEffectDeps={[departmentSearch]}
            responsePath="departments.departments"
            valuePath="id"
            labelPath="name"
            fetchPolicy="network-only"
            onClear={() =>
              setInitialFilter({
                ...initialFilter,
                searchStrings: [],
              })
            }
            value={initialFilter?.departmentId}
            virtual={false}
          />
          <Space direction="horizontal">
            <Button size="small" onClick={handleFilter}>
              Apply
            </Button>
            <Button
              danger
              size="small"
              onClick={() => {
                handleReset('department');
                setInitialFilter({
                  ...initialFilter,
                  departmentId: null,
                });
                setFilterVisible({
                  ...filterVisible,
                  departmentFilterVisible: false,
                });
              }}
            >
              Reset
            </Button>
          </Space>
        </Space>
      </div>
    ),
    filterIcon: () => (
      <FilterFilled
        className={
          finalFilter?.department || initialFilter?.departmentId
            ? 'filter-filled-data'
            : ''
        }
      />
    ),
  });

  const getProjectFilterProps = () => ({
    filterDropdownOpen: filterVisible?.billableFilterVisible,
    onFilterDropdownOpenChange: (visible) =>
      setFilterVisible({
        ...filterVisible,
        billableFilterVisible: visible,
      }),
    filterDropdown: () => (
      <div className="columnFilter" onKeyDown={(e) => e?.stopPropagation()}>
        <Space direction="vertical">
          <Form name="control-ref" form={form} layout="vertical">
            <Form.Item name="Billable" valuePropName="checked">
              <Radio.Group onChange={handleBillableFilter}>
                <Space direction="vertical">
                  <Radio value>
                    {BILLING_TYPE?.BILLABLE?.charAt(0)?.toUpperCase()}
                    {BILLING_TYPE?.BILLABLE?.slice(1)}
                  </Radio>
                  <Radio value={false}>
                    {BILLING_TYPE?.NON_BILLABLE?.charAt(0)?.toUpperCase()}
                    {BILLING_TYPE?.NON_BILLABLE?.slice(1)}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Form>
          <Space direction="horizontal">
            <Button size="small" onClick={handleFilter}>
              Apply
            </Button>
            <Button
              danger
              size="small"
              onClick={() => {
                handleReset(BILLING_TYPE?.BILLABLE);
                setFilterVisible({
                  ...filterVisible,
                  billableFilterVisible: false,
                });
              }}
            >
              Reset
            </Button>
          </Space>
        </Space>
      </div>
    ),
    render: (value) =>
      value && (
        <div className="billable">
          <DollarOutlined />
        </div>
      ),
    filterIcon: () => (
      <FilterFilled
        className={isBoolean(finalFilter?.billable) ? 'filter-filled-data' : ''}
      />
    ),
  });

  const handleSync = async () => {
    const syncUrls = [
      process.env.REACT_APP_SYNC_USERS_URL,
      process.env.REACT_APP_SYNC_DEPARTMENT_URL,
    ];
    try {
      await Promise?.all(
        syncUrls?.map(async (url) => {
          await api?.post(url);
        }),
      );
      message?.destroy();
      message?.success(SYNC_MESSAGE);
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.extensions?.code === SESSION_EXPIRED
      ) {
        message?.destroy();
        message?.error(ERROR_MESSAGE);
        history?.replace({
          pathname: ROUTES?.AUTHENTICATION,
          // eslint-disable-next-line no-undef
          state: { from: window?.location?.pathname },
        });
      }
    }
  };

  const handleToggleSwitch = (event) => {
    setInitialFilter({
      ...initialFilter,
      showInactiveUsers: event,
    });
    executeQuery({
      variables: {
        filters: {
          ...initialFilter,
          showInactiveUsers: event,
          limit: paginationFilter?.pageSize,
        },
      },
    });
  };

  const handleAddUserIntoChat = async (value, actionType) => {
    setDisabledButtons((prev) => ({
      ...prev,
      [value?.id]: {
        ...prev[value?.id],
        [actionType]: true,
      },
    }));
    try {
      const {
        data: {
          checkAuthForChat: { isAuthenticatedForChat },
        },
      } = await checkAuthForChat({
        variables: {
          input: {
            uniqueId: process.env.REACT_APP_ADD_USER_INTO_SPACE_AND_CHAT,
            refType:
              ADD_USER_CHAT_OR_SCRUM_REF_TYPE.COMMON_SPACE_MEMBERS_MANAGEMENT,
          },
        },
      });

      if (isAuthenticatedForChat) {
        await addUserToChatSpace({
          variables: {
            where: {
              id: value?.id,
            },
          },
        });
      } else {
        const {
          data: {
            getChatAuthUrl: { url },
          },
        } = await getChatAuthUrl({
          variables: {
            input: {
              uniqueId: process.env.REACT_APP_ADD_USER_INTO_SPACE_AND_CHAT,
              refType:
                ADD_USER_CHAT_OR_SCRUM_REF_TYPE.COMMON_SPACE_MEMBERS_MANAGEMENT,
            },
          },
        });
        if (url) {
          // eslint-disable-next-line no-undef
          window?.open(url);
        }
      }
    } catch (error) {
      return error;
    } finally {
      setDisabledButtons((prev) => ({
        ...prev,
        [value?.id]: {
          ...prev[value?.id],
          [actionType]: false,
        },
      }));
    }
  };

  const handleAddUserIntoScrumCall = async (value, actionType) => {
    setDisabledButtons((prev) => ({
      ...prev,
      [value?.id]: {
        ...prev[value?.id],
        [actionType]: true,
      },
    }));
    try {
      const {
        data: {
          checkAuthForCalendar: { isAuthenticatedForCalendar },
        },
      } = await checkAuthForCalendar({
        variables: {
          input: {
            uniqueId: process.env.REACT_APP_ADD_USER_INTO_SPACE_AND_CHAT,
            refType:
              ADD_USER_CHAT_OR_SCRUM_REF_TYPE.MORNING_CALL_MEMBERS_MANAGEMENT,
          },
        },
      });

      if (isAuthenticatedForCalendar) {
        await addUserToMorningCall({
          variables: {
            where: {
              id: value?.id,
            },
          },
        });
      } else {
        const {
          data: {
            getCalendarAuthUrl: { url },
          },
        } = await getCalenderAuthUrl({
          variables: {
            input: {
              refType:
                ADD_USER_CHAT_OR_SCRUM_REF_TYPE.MORNING_CALL_MEMBERS_MANAGEMENT,
            },
          },
        });
        if (url) {
          // eslint-disable-next-line no-undef
          window?.open(url);
        }
      }
    } catch (e) {
      return e;
    } finally {
      setDisabledButtons((prev) => ({
        ...prev,
        [value?.id]: {
          ...prev[value?.id],
          [actionType]: false,
        },
      }));
    }
  };

  const handleSummarySync = async () => {
    const syncUrls = process.env.REACT_APP_SYNC_SUMMARY;
    try {
      await new Promise((resolve, reject) => {
        api
          ?.post(syncUrls)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
      message?.destroy();
      message?.success(SYNC_MESSAGE);
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.extensions?.code === SESSION_EXPIRED
      ) {
        message?.destroy();
        message?.error(ERROR_MESSAGE);
        history?.replace({
          pathname: ROUTES?.AUTHENTICATION,
          // eslint-disable-next-line no-undef
          state: { from: window?.location?.pathname },
        });
      }
    }
  };

  const handleSyncSummaryById = async (userID) => {
    const syncUrls = `${process.env.REACT_APP_SYNC_SUMMARY}/${userID}`;
    try {
      await new Promise((resolve, reject) => {
        api
          ?.post(syncUrls)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
      message?.destroy();
      message?.success(SYNC_MESSAGE);
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.extensions?.code === SESSION_EXPIRED
      ) {
        message?.destroy();
        message?.error(ERROR_MESSAGE);
        history?.replace({
          pathname: ROUTES?.AUTHENTICATION,
          // eslint-disable-next-line no-undef
          state: { from: window?.location?.pathname },
        });
      }
    }
  };

  const columns = [
    {
      dataIndex: 'billable',
      key: 'billable',
      className: 'billable-filter',
      ...getProjectFilterProps(),
    },
    {
      title: 'Add',
      key: 'Add',
      render: (record) => (
        <Space size="small">
          <Tooltip title="Add User To Chat">
            <Button
              className="border-none"
              disabled={disabledButtons[record?.id]?.chat}
            >
              <GoogleChat
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddUserIntoChat(record, ADD_USER_TO_CHAT_OR_MEET.CHAT);
                }}
              />
            </Button>
          </Tooltip>
          <Tooltip title="Add User To Morning Call">
            <Button
              className="border-none"
              disabled={disabledButtons[record?.id]?.meet}
            >
              <GoogleMeet
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddUserIntoScrumCall(
                    record,
                    ADD_USER_TO_CHAT_OR_MEET.MEET,
                  );
                }}
              />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'Resume',
      align: 'center',
      render: (record) =>
        !record?.isIntern && (
          <Button
            className="user-resume-btn"
            type="text"
            onClick={(e) => {
              e?.stopPropagation();
              handleUserResume(record?.id);
            }}
            target="_blank"
          >
            <UserResume />
          </Button>
        ),
    },
    ...([ROLE_KEYS?.SUPER_ADMIN, ROLE_KEYS?.ADMIN]?.includes(user?.role) ||
    [LEVEL?.L1, LEVEL?.L2]?.includes(user?.level?.name)
      ? [
          {
            title: 'Summary',
            align: 'center',
            render: (record) =>
              !record?.isIntern && (
                <Tooltip
                  title={`Click here to update ${record?.displayName}'s professional summaries.`}
                >
                  <Button
                    className="user-sync-btn"
                    onClick={(e) => {
                      e?.stopPropagation();
                      handleSyncSummaryById(record?.id);
                    }}
                  >
                    <FileSyncOutlined />
                  </Button>
                </Tooltip>
              ),
          },
        ]
      : []),
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
      sorter: true,
      filterMultiple: false,
      ...getNameFilterProps('Last Name'),
      width: '25%',
      defaultSortOrder:
        initialFilter?.sortField === 'displayName' ? activeSort : null,
      className: 'link-custom-width',
      render: (value, record) => (
        <Link
          to={{
            pathname: `${ROUTES_MODULES_KEY?.USERS}/${record?.id}`,
            state: {
              initialFilter: { ...initialFilter },
              paginationFilter: { ...paginationFilter },
            },
          }}
        >
          {record?.displayName}
        </Link>
      ),
    },
    {
      title: 'Designation',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      ...getJobTitleFilterProps(),
      width: '25%',
      className: 'link-custom-width',
      render: (value, record) => (
        <Link
          to={{
            pathname: `${ROUTES_MODULES_KEY?.USERS}/${record?.id}`,
            state: {
              initialFilter: { ...initialFilter },
              paginationFilter: { ...paginationFilter },
            },
          }}
        >
          {record?.jobTitle}
        </Link>
      ),
    },
    {
      title: 'Department',
      dataIndex: ['department', 'name'],
      key: 'department',
      ...getDepartmentFilterProps(),
      width: '25%',
      className: 'link-custom-width',
      render: (value, record) => (
        <Link
          to={{
            pathname: `${ROUTES_MODULES_KEY?.USERS}/${record?.id}`,
            state: {
              initialFilter: { ...initialFilter },
              paginationFilter: { ...paginationFilter },
            },
          }}
        >
          {record?.department?.name}
        </Link>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      width: '25%',
      defaultSortOrder:
        initialFilter?.sortField === 'email' ? activeSort : null,
      className: 'link-custom-width',
      render: (value, record) => (
        <Link
          to={{
            pathname: `${ROUTES_MODULES_KEY?.USERS}/${record?.id}`,
            state: {
              initialFilter: { ...initialFilter },
              paginationFilter: { ...paginationFilter },
            },
          }}
        >
          {record?.email}
        </Link>
      ),
    },
  ];

  const renderMaxTag = (omittedValues) => {
    if (omittedValues?.length === 0) {
      return null;
    }

    return <span>+ {omittedValues?.length} more...</span>;
  };

  const handleRowClick = (record) => {
    history?.push({
      pathname: `${ROUTES_MODULES_KEY?.USERS}/${record?.id}`,
      state: {
        initialFilter: { ...initialFilter },
        paginationFilter: { ...paginationFilter },
      },
    });
  };

  const handleBeforeUnload = () => {
    history.push({
      pathname: history.location.pathname,
      state: null,
    });
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div className="table-card-page">
      <Card
        className="ant-body-scroll"
        title="Users"
        extra={
          <>
            <div className="project-filter-right">
              <div className="project-filter">
                {([ROLE_KEYS?.SUPER_ADMIN, ROLE_KEYS?.ADMIN]?.includes(
                  user?.role,
                ) ||
                  [LEVEL?.L1, LEVEL?.L2]?.includes(user?.level?.name)) && (
                  <Tooltip placement="bottom" title={<span>User Sync</span>}>
                    <Button onClick={handleSync}>
                      <SyncOutlined />
                    </Button>
                  </Tooltip>
                )}
                {([ROLE_KEYS?.SUPER_ADMIN, ROLE_KEYS?.ADMIN]?.includes(
                  user?.role,
                ) ||
                  [LEVEL?.L1, LEVEL?.L2]?.includes(user?.level?.name)) && (
                  <Tooltip
                    placement="bottom"
                    title={
                      <span>
                        Click here to update all employee's professional
                        summaries.
                      </span>
                    }
                  >
                    <Button>
                      <FileSyncOutlined onClick={handleSummarySync} />
                    </Button>
                  </Tooltip>
                )}
                <span>
                  <Tooltip
                    title={
                      initialFilter?.showInactiveUsers
                        ? 'Include Inactive Users'
                        : 'All Active Users'
                    }
                  >
                    <Switch
                      checkedChildren="All"
                      unCheckedChildren="Active"
                      defaultChecked
                      onChange={handleToggleSwitch}
                    />
                  </Tooltip>
                </span>
                {isUserCreatePermission && (
                  <Button
                    key="1"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      history?.push({
                        pathname: `${ROUTES_MODULES_KEY?.USERS}/create`,
                      });
                    }}
                  >
                    Create User
                  </Button>
                )}
                <span className="user-select-tags">
                  <CommonSelect
                    mode="tags"
                    placeholder="Search Name, Department, Designation"
                    showSearch
                    query={DESIGNATIONS}
                    onChange={handleSearch}
                    onSearch={handleDesignationDataSearch}
                    variables={{ searchString: designationDataSearch }}
                    useEffectDeps={[designationDataSearch]}
                    responsePath="designations.designations"
                    valuePath="jobTitle"
                    labelPath="jobTitle"
                    fetchPolicy="network-only"
                    onClear={() =>
                      setInitialFilter({
                        ...initialFilter,
                        searchStrings: [],
                      })
                    }
                    value={initialFilter?.searchStrings}
                    maxTagCount={3}
                    maxTagPlaceholder={renderMaxTag}
                    virtual={false}
                  />
                </span>
              </div>
            </div>
          </>
        }
      >
        <div className="card-body-wrapper">
          <CommonTable
            size="small"
            columns={columns}
            dataSource={data?.usersAdmin?.users}
            rowKey={(obj) => obj?.id}
            onChange={onChange}
            loadingData={loading}
            bordered
            paginationConfig={{
              current: paginationFilter?.currentPage,
              total: data?.usersAdmin?.count,
              pageSize: paginationFilter?.pageSize,
            }}
            onRow={(record) => ({ onClick: () => handleRowClick(record) })}
            rowClassName="user-table-row"
            className="user-table"
          />
        </div>
      </Card>
    </div>
  );
}

export default Users;
